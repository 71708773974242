var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pl-12 pr-12", attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && !_vm.isAvailable,
              expression: "!isLoading && !isAvailable"
            }
          ],
          attrs: { column: "", wrap: "" }
        },
        [
          _c("p", { staticClass: "mt-2" }, [_vm._v(_vm._s(_vm.errorMessage))]),
          _c(
            "v-btn",
            {
              staticClass: "font-weight-black float-right mr-4",
              staticStyle: { "font-size": "20px" },
              attrs: {
                width: "70vw",
                "max-width": "350px",
                color: "next",
                dark: ""
              },
              on: {
                click: function($event) {
                  return _vm.onClickBackHomeButton()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("button.backHome")))]
          )
        ],
        1
      ),
      _c(
        "v-layout",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isLoading && _vm.isAvailable,
              expression: "!isLoading && isAvailable"
            }
          ],
          attrs: { column: "", wrap: "" }
        },
        [
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "p",
                  {
                    staticClass: "font-weight-black pl-3 pb-0 mb-0",
                    staticStyle: { "font-size": "32px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("title.agency.contractDetail")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "pb-0" }),
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black float-right",
                                  staticStyle: { "font-size": "20px" },
                                  attrs: {
                                    align: "center",
                                    width: "25vw",
                                    "max-width": "300px",
                                    color: "next",
                                    dark: _vm.isStatusChangeButtonEnabled,
                                    disabled: !_vm.isStatusChangeButtonEnabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickStatusChangeButton()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("button.statusChange")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border mt-2",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.statusRecords, function(status) {
                              return _c(
                                "tr",
                                { key: status.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "header.baseInfoTable." +
                                                status.key
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  [
                                    _c(
                                      "td",
                                      [
                                        status.value
                                          ? _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    class: _vm.checkLockoutStyle(),
                                                    staticStyle: {
                                                      flex: "auto",
                                                      width: "auto"
                                                    },
                                                    attrs: {
                                                      "align-self": "center"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "mb-0",
                                                        staticStyle: {
                                                          "font-size": "14px"
                                                        },
                                                        attrs: { align: "left" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              status.value
                                                            ) +
                                                            "\n                          "
                                                        ),
                                                        _vm.isLocked
                                                          ? [
                                                              _vm._v(
                                                                "\n                            (" +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "label.lockout"
                                                                    )
                                                                  ) +
                                                                  ")\n                          "
                                                              )
                                                            ]
                                                          : _vm._e()
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                ),
                                                _vm.isEnabledlockoutRelease
                                                  ? _c(
                                                      "v-col",
                                                      {
                                                        staticStyle: {
                                                          flex: "auto",
                                                          width: "auto"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "font-weight-black float-right",
                                                            attrs: {
                                                              align: "center",
                                                              color: "next",
                                                              dark:
                                                                _vm.isEnabledlockoutRelease,
                                                              disabled: !_vm.isEnabledlockoutRelease
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onClickLockoutRelease()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.lockoutText
                                                                  .button
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "pb-0" }),
                          _c(
                            "v-col",
                            { staticClass: "pb-0" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "font-weight-black float-right",
                                  staticStyle: { "font-size": "20px" },
                                  attrs: {
                                    slot: "activator",
                                    align: "center",
                                    width: "25vw",
                                    "max-width": "350px",
                                    color: "next",
                                    dark: _vm.isNoteEditButtonEnabled,
                                    disabled: !_vm.isNoteEditButtonEnabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickEditNoteButton()
                                    }
                                  },
                                  slot: "activator"
                                },
                                [_vm._v(_vm._s(_vm.$t("button.updateMemo")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border mt-2",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.noteRecords, function(note) {
                              return _c(
                                "tr",
                                { key: note.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "header.baseInfoTable." + note.key
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "py-4" },
                                      _vm._l(note.value, function(
                                        value,
                                        index
                                      ) {
                                        return _c(
                                          "p",
                                          {
                                            key: index,
                                            staticClass: "ma-0 pa-0",
                                            attrs: { align: "left" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(value) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0 mb-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "pb-0 mb-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.unditableBaseInfo")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.uneditableLeftBaseRecords, function(
                              baseRecord
                            ) {
                              return _c(
                                "tr",
                                { key: baseRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text pl-1"
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "header.baseInfoTable." +
                                                              baseRecord.key
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  [
                                    _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(baseRecord.value) +
                                          "\n                  "
                                      )
                                    ])
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.uneditableRightBaseRecords, function(
                              baseRecord
                            ) {
                              return _c(
                                "tr",
                                { key: baseRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text pl-1"
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "header.baseInfoTable." +
                                                              baseRecord.key
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  [
                                    _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(baseRecord.value) +
                                          "\n                  "
                                      )
                                    ])
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "pb-0 mb-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.editableBaseInfo")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { staticClass: "pb-0 mb-0 mr-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black float-right",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        slot: "activator",
                        align: "center",
                        width: "70vw",
                        "max-width": "350px",
                        color: "next",
                        dark: _vm.isBaseInfoEditButtonEnabled,
                        disabled: !_vm.isBaseInfoEditButtonEnabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickEditContractButton()
                        }
                      },
                      slot: "activator"
                    },
                    [_vm._v(_vm._s(_vm.$t("label.editContract")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.editableLeftBaseRecords, function(
                              baseRecord
                            ) {
                              return _c(
                                "tr",
                                { key: baseRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text pl-1"
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c("v-col", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "header.baseInfoTable." +
                                                              baseRecord.key
                                                          )
                                                        )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  [
                                    _c("td", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(baseRecord.value) +
                                          "\n                  "
                                      )
                                    ])
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 pl-0" },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.editableRightBaseRecords, function(
                              baseRecord
                            ) {
                              return _c(
                                "tr",
                                { key: baseRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text pl-1"
                                    },
                                    [
                                      _c(
                                        "v-container",
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "14px"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "header.baseInfoTable." +
                                                                baseRecord.key
                                                            )
                                                          )
                                                      )
                                                    ]
                                                  ),
                                                  baseRecord.showToCustomer
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass:
                                                            "px-0 py-0 float-right my-auto",
                                                          staticStyle: {
                                                            width: "24px",
                                                            height: "100%"
                                                          }
                                                        },
                                                        [_vm._v("mdi-star")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  [
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticStyle: {
                                                  flex: "auto",
                                                  width: "auto"
                                                },
                                                attrs: {
                                                  "align-self": "center"
                                                }
                                              },
                                              [
                                                baseRecord.key === "comment"
                                                  ? _vm._l(
                                                      baseRecord.value,
                                                      function(value, index) {
                                                        return _c(
                                                          "p",
                                                          {
                                                            key: index,
                                                            staticClass:
                                                              "ma-0 pa-0",
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px"
                                                            },
                                                            attrs: {
                                                              align: "left"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(value) +
                                                                "\n                          "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  : [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass: "mb-0",
                                                          staticStyle: {
                                                            "font-size": "14px"
                                                          },
                                                          attrs: {
                                                            align: "left"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                            " +
                                                              _vm._s(
                                                                baseRecord.value
                                                              ) +
                                                              "\n                          "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                              ],
                                              2
                                            ),
                                            baseRecord.key === "extraFile"
                                              ? _c(
                                                  "v-col",
                                                  {
                                                    staticStyle: {
                                                      flex: "auto",
                                                      width: "auto"
                                                    }
                                                  },
                                                  [
                                                    baseRecord.key ==
                                                    "extraFile"
                                                      ? _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "mt-1 mb-2 font-weight-black float-right",
                                                            attrs: {
                                                              align: "center",
                                                              color: "next",
                                                              dark:
                                                                _vm.isExtraFileUploadButtonEnabled,
                                                              disabled: !_vm.isExtraFileUploadButtonEnabled
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.onClickExtraFileUploadButton()
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "button.otherUpload"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "px-0 py-3" },
                        [
                          _c(
                            "v-icon",
                            { staticStyle: { width: "24px", height: "100%" } },
                            [_vm._v("mdi-star\n            ")]
                          ),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t(
                                  "description.contractDetailTop.explanation1"
                                )
                              ) +
                              "\n          "
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0 mb-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "mb-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.PlanList")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "mb-1", staticStyle: { width: "100%" } },
            [
              _c(
                "v-col",
                [
                  _c("v-data-table", {
                    staticClass: "elevation-1",
                    staticStyle: { width: "100%", display: "block" },
                    attrs: {
                      "calculate-widths": "",
                      headers: _vm.headers,
                      items: _vm.records,
                      "hide-default-footer": true,
                      "no-data-text": _vm.$t("error.planNotFound")
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header.planName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.planName"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.insurancePeriodText",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.insurancePeriodText"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.earthquakeInsurancePeriodText",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.earthquakeInsurancePeriodText"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.grandTotalPrice",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.grandTotalPrice"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.totalPremium",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.totalPremium"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.paymentTypeName",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.paymentTypeName"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.message",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("header.planListTable.message")
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#cc0022"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "header.planListTable.messageComment"
                                          )
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mr-3 my-auto",
                                    attrs: { cols: "1" }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-star")])],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "header.comment",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "14px",
                                        color: "#696969"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("header.planListTable.comment")
                                        )
                                      )
                                    ]
                                  )
                                ]),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "mr-3 my-auto",
                                    attrs: { cols: "1" }
                                  },
                                  [_c("v-icon", [_vm._v("mdi-star")])],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "item",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "tr",
                              {
                                class: {
                                  selectedPlan: _vm.showBackgroundColor(item.id)
                                }
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-left"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.planName) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-left"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.insurancePeriodText) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-left"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          item.earthquakeInsurancePeriodText
                                        ) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-right"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.grandTotalPrice) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-right"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.totalPremium) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-left"
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.paymentTypeName) +
                                        "\n              "
                                    )
                                  ]
                                ),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "v-data-table__divider text-left",
                                    staticStyle: {
                                      "padding-left": "0px",
                                      "padding-right": "0px"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-row",
                                      { staticStyle: { margin: "auto" } },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: { width: "auto" },
                                            attrs: { "align-self": "center" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "ma-0 pa-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(item.attachDoc) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c("hr", {
                                      attrs: {
                                        color: "#95949a",
                                        size: "1px",
                                        noshade: ""
                                      }
                                    }),
                                    _c(
                                      "v-row",
                                      {
                                        style: _vm.showAttachDocBackgroundColor(
                                          item.id
                                        )
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: { width: "auto" },
                                            attrs: { "align-self": "center" }
                                          },
                                          _vm._l(item.message, function(
                                            message,
                                            index
                                          ) {
                                            return _c(
                                              "p",
                                              {
                                                key: index,
                                                staticClass: "ma-0 pa-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(message) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: {
                                              flex: "auto",
                                              width: "auto"
                                            },
                                            attrs: { "align-self": "center" }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-black float-right",
                                                attrs: {
                                                  align: "center",
                                                  color: "next",
                                                  dark:
                                                    _vm.isEditPlanMessageButtonEnabled,
                                                  disabled: !_vm.isEditPlanMessageButtonEnabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickEditPlanMessageButton(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "header.planListTable.edit"
                                                    )
                                                  ) + "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  { staticClass: "text-center__divider" },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: {
                                              flex: "auto",
                                              width: "auto"
                                            },
                                            attrs: { "align-self": "center" }
                                          },
                                          _vm._l(item.comment, function(
                                            comment,
                                            index
                                          ) {
                                            return _c(
                                              "p",
                                              {
                                                key: index,
                                                staticClass: "ma-0 pa-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(comment) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: {
                                              flex: "auto",
                                              width: "auto"
                                            },
                                            attrs: { "align-self": "center" }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass:
                                                  "font-weight-black float-right",
                                                attrs: {
                                                  align: "center",
                                                  color: "next",
                                                  dark:
                                                    _vm.isEditPlanCommentButtonEnabled,
                                                  disabled: !_vm.isEditPlanCommentButtonEnabled
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.onClickEditPlanCommentButton(
                                                      item.id
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "header.planListTable.edit"
                                                    )
                                                  ) + "\n                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "pb-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "pb-0 mb-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.agencyInfo")) +
                        "\n        "
                    )
                  ]
                )
              ]),
              _c(
                "v-col",
                { staticClass: "pb-0 mb-0 mr-3" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black float-right",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        slot: "activator",
                        align: "center",
                        width: "70vw",
                        "max-width": "350px",
                        color: "next",
                        dark: _vm.isAgencyInfoEditButtonEnabled,
                        disabled: !_vm.isAgencyInfoEditButtonEnabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickEditAgencyButton()
                        }
                      },
                      slot: "activator"
                    },
                    [_vm._v(_vm._s(_vm.$t("label.editAgency")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.leftAgencyRecords, function(
                              agencyRecord
                            ) {
                              return _c("tr", { key: agencyRecord.key }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-left .v_base_table_with_border base_td_category_text pl-1"
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "header.baseInfoTable." +
                                                              agencyRecord.key
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                agencyRecord.showToCustomer
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "px-0 py-0 float-right my-auto",
                                                        staticStyle: {
                                                          width: "24px",
                                                          height: "100%"
                                                        }
                                                      },
                                                      [_vm._v("mdi-star")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticStyle: {
                                              flex: "auto",
                                              width: "auto"
                                            },
                                            attrs: { "align-self": "center" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "mb-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(agencyRecord.value) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "pb-0" },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.rightAgencyRecords, function(
                              agencyRecord
                            ) {
                              return _c("tr", { key: agencyRecord.key }, [
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "text-left .v_base_table_with_border base_td_category_text pl-1"
                                  },
                                  [
                                    _c(
                                      "v-container",
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "14px"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "header.baseInfoTable." +
                                                              agencyRecord.key
                                                          )
                                                        )
                                                    )
                                                  ]
                                                ),
                                                agencyRecord.showToCustomer
                                                  ? _c(
                                                      "v-icon",
                                                      {
                                                        staticClass:
                                                          "px-0 py-0 float-right my-auto",
                                                        staticStyle: {
                                                          width: "24px",
                                                          height: "100%"
                                                        }
                                                      },
                                                      [_vm._v("mdi-star")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "py-0",
                                            staticStyle: {
                                              flex: "auto",
                                              width: "auto"
                                            },
                                            attrs: { "align-self": "center" }
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "mb-0",
                                                staticStyle: {
                                                  "font-size": "14px"
                                                },
                                                attrs: { align: "left" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(agencyRecord.value) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        [
                                          agencyRecord.key === "staffName" ||
                                          agencyRecord.key === "agencyName"
                                            ? _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mr-1 font-weight-black float-right",
                                                  attrs: {
                                                    align: "center",
                                                    color: "next",
                                                    dark:
                                                      _vm.isReflectContractInfoButtonEnabled,
                                                    disabled: !_vm.isReflectContractInfoButtonEnabled
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.onClickReflectButton(
                                                        agencyRecord.value,
                                                        _vm.$t(
                                                          "header.baseInfoTable." +
                                                            agencyRecord.key
                                                        )
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "button.reflectContractInfo"
                                                      )
                                                    ) +
                                                      "\n                      "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ],
                                      2
                                    )
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "my-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.salesInfo")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "pb-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "ml-0", attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.salesRecords, function(salesRecord) {
                              return _c(
                                "tr",
                                { key: salesRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "header.salesInfoTable." +
                                                salesRecord.key
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  [
                                    _c("td", [
                                      _vm._v(_vm._s(salesRecord.value))
                                    ])
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "pb-0 pl-0",
                  staticStyle: { height: "100%", visibility: "hidden" }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "ml-0", attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(["dummy0", "dummy1", "dummy2"], function(
                              dummyRecord,
                              index
                            ) {
                              return _c("tr", { key: index }, [
                                _c("td", {
                                  staticClass:
                                    "text-left .v_base_table_with_border base_td_category_text"
                                }),
                                _c("td")
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "py-0" },
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { staticClass: "py-0" },
                    [
                      _c(
                        "v-row",
                        [
                          _c("v-col", { staticClass: "py-0" }, [
                            _c(
                              "p",
                              {
                                staticClass: "py-0 mb-0 font-weight-black",
                                staticStyle: { "font-size": "20px" }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("label.sendMessage")) +
                                    "\n                "
                                ),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass:
                                      "font-weight-black float-right",
                                    staticStyle: { "font-size": "20px" },
                                    attrs: {
                                      slot: "activator",
                                      align: "center",
                                      width: "15vw",
                                      "max-width": "350px",
                                      color: "next",
                                      dark: _vm.isNoteEditButtonEnabled,
                                      disabled: !_vm.isNoteEditButtonEnabled
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickSendMessageButton()
                                      }
                                    },
                                    slot: "activator"
                                  },
                                  [_vm._v(_vm._s(_vm.$t("button.view")))]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border mt-2",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.sendMessageRecords, function(
                              sendMessage
                            ) {
                              return _c(
                                "tr",
                                { key: sendMessage.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text"
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "description.sendMessageTitle"
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "py-4" },
                                      _vm._l(sendMessage.value, function(
                                        value,
                                        index
                                      ) {
                                        return _c(
                                          "p",
                                          {
                                            key: index,
                                            staticClass: "ma-0 pa-0",
                                            attrs: { align: "left" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(value) +
                                                "\n                    "
                                            )
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 pl-0",
                  staticStyle: { height: "100%", visibility: "hidden" }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "ml-0", attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(["dummy0"], function(dummyRecord, index) {
                              return _c("tr", { key: index }, [
                                _c("td", {
                                  staticClass:
                                    "text-left .v_base_table_with_border base_td_category_text"
                                }),
                                _c("td")
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", { staticClass: "py-0" }, [
                _c(
                  "p",
                  {
                    staticClass: "pb-0 mb-0 font-weight-black",
                    staticStyle: { "font-size": "20px" }
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("label.contractorConfirmResult")) +
                        "\n        "
                    )
                  ]
                )
              ])
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "py-0 pl-0", staticStyle: { height: "100%" } },
                [
                  _c(
                    "v-container",
                    { attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.leftConfirmRecords, function(
                              confirmRecord
                            ) {
                              return _c(
                                "tr",
                                { key: confirmRecord.key },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "text-left .v_base_table_with_border base_td_category_text",
                                      class: {
                                        next: false,
                                        "white--text": false
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "header.confirmInfoTable." +
                                                confirmRecord.key
                                            )
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  confirmRecord.key === "insuredUpload"
                                    ? [
                                        _c("td", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.showCustomerUploads
                                                  ? _vm.doesInsuredUploadExist(
                                                      confirmRecord.value
                                                    )
                                                    ? _vm.$t(
                                                        "button.insuredUpload.exist"
                                                      )
                                                    : _vm.$t(
                                                        "button.insuredUpload.notExist"
                                                      )
                                                  : ""
                                              ) +
                                              "\n                  "
                                          )
                                        ])
                                      ]
                                    : [
                                        _c("td", [
                                          _vm._v(_vm._s(confirmRecord.value))
                                        ])
                                      ]
                                ],
                                2
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "py-0 pl-0",
                  staticStyle: { height: "100%", visibility: "hidden" }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "ml-0", attrs: { "fill-height": "" } },
                    [
                      _c(
                        "v-simple-table",
                        {
                          staticClass:
                            "elevation-1 v_base_table_fill_width v_base_table_with_border",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(["dummy0", "dummy1", "dummy2"], function(
                              dummyRecord,
                              index
                            ) {
                              return _c("tr", { key: index }, [
                                _c("td", {
                                  staticClass:
                                    "text-left .v_base_table_with_border base_td_category_text"
                                }),
                                _c("td")
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            { staticClass: "pt-10" },
            [
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "max-width": "300px",
                        color: "next",
                        dark: !_vm.isSendMsgButtonDisabled,
                        disabled: _vm.isSendMsgButtonDisabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.choiceDialog()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.sendMsg")))]
                  )
                ],
                1
              ),
              _c(
                "v-col",
                { staticStyle: { "text-align": "center" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "font-weight-black",
                      staticStyle: { "font-size": "20px" },
                      attrs: {
                        align: "center",
                        width: "25vw",
                        "min-width": "280px",
                        "max-width": "300px",
                        color: "next",
                        dark: _vm.isFilePrintAndDownloadButtonEnabled,
                        disabled: !_vm.isFilePrintAndDownloadButtonEnabled
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClickFilePrintAndDownloadButton()
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("button.filePrintAndDownload")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("ReflectContractInfoDialog", {
            ref: "reflectContractInfoDialog",
            on: { onSuccess: _vm.onSuccessReflectLoginUserInfo }
          }),
          _c("SendingConfirmDialog", {
            ref: "sendingConfirmDialog",
            on: { onSuccess: _vm.onSuccessSendingConfirm }
          }),
          _c("EditExtraFileDialog", {
            ref: "editExtraFileDialog",
            attrs: {
              files: _vm.extraFilelist,
              onClickCreate: _vm.onClickExtraFileCreate,
              onClickPreview: _vm.onClickExtraFilePreview,
              onClickEdit: _vm.onClickExtraFileEdit,
              onClickDelete: _vm.onClickExtraFileDelete
            }
          }),
          _c("FileListDialog", { ref: "fileListDialog" }),
          _c("SendMessageDialog", {
            ref: "sendMessageDialog",
            attrs: {
              smsMessageRecords: _vm.smsMessageRecords,
              mailMessageRecords: _vm.mailMessageRecords
            },
            on: { onSuccess: _vm.onSuccessSendMessage }
          }),
          _c("SelectAndEditSendMessageDialog", {
            ref: "selectAndEditSendMessageDialog",
            on: { onSuccess: _vm.onSuccessSelectAndEditSendMessage }
          }),
          _c("ErrorDialog", { ref: "errorDialog" }),
          _c("CompletedDialog", { ref: "completedDialog" }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditBaseInfoDialog,
              title: _vm.$t("label.editContract"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditBaseInfo,
              onClickPositiveButton: _vm.updateBaseInfoConfirm,
              targets: _vm.editBaseInfoTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditAgencyInfoDialog,
              title: _vm.$t("label.editAgency"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditAgencyInfo,
              onClickPositiveButton: _vm.updateAgencyInfo,
              targets: _vm.editAgencyInfoTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditPlanMessageDialog,
              title: _vm.$t("label.editPlanMessage"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditPlanMessage,
              onClickPositiveButton: _vm.updatePlanMessage,
              targets: _vm.editPlanMessageTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditPlanCommentDialog,
              title: _vm.$t("label.editPlanComment"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditPlanComment,
              onClickPositiveButton: _vm.updatePlanComment,
              targets: _vm.editPlanCommentTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showSelectSendMethodAndAuthMethodDialog,
              title: _vm.selectSendMethodAndAuthMethodDialogTitle,
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.confirm"),
              onClickNegativeButton:
                _vm.cancelSelectSendMethodAndAuthMethodDialog,
              onClickPositiveButton: _vm.sendingConfirm,
              targets: _vm.selectSendMethodAndAuthMethodTargets
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showResendingConfirmDialog,
              title: _vm.$t("label.resendingConfirm"),
              text: null,
              subText: null,
              footerNote: null,
              multiLineText: null,
              confirmText: _vm.$t(
                "description.sendMailAndSms.resendingConfirmText"
              ),
              negativeButtonTitle: _vm.$t("button.no"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.cancelResendingConfirmDialog,
              onClickPositiveButton: _vm.resendingConfirm
            }
          }),
          _c("DeleteDialog", {
            ref: "contractDeleteConfirmDialog",
            attrs: { title: _vm.$t("title.agency.contractDeleteConfirm") },
            on: { onSuccess: _vm.onSuccessContractDeleteConfirm }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showStatusChangeDialog,
              title: _vm.$t("title.agency.statusChange"),
              text: _vm.statusChangeDialogText,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.change"),
              onClickNegativeButton: _vm.cancelStatusChange,
              onClickPositiveButton: _vm.confirmStatusChange,
              targets: _vm.editStatusTargets
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showStatusChangeConfirmDialog,
              title: _vm.$t("title.agency.statusChangeConfirm"),
              text: null,
              subText: null,
              multiLineText: _vm.statusChangeConfirmDialogConfirmText,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.change"),
              onClickNegativeButton: _vm.cancelStatusChangeConfirm,
              onClickPositiveButton: _vm.updateStatus,
              targets: _vm.editStatusChangeConfirmTargets,
              disabled: false
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              maxWidth: 900,
              showDialog: _vm.showSendPreviewDialog,
              title: _vm.$t("label.sendPreview"),
              subMessage: _vm.noticeOfModificationContent,
              footerNote: _vm.$t("description.messageContent.footerNote"),
              multiLineText: _vm.sendMessageForPreview,
              confirmText: null,
              negativeButtonTitle: _vm.$t("button.back"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.closeSendPreviewDialog,
              onClickPositiveButton: _vm.saveSendMessage
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showUrlInvalidationWarningDialog,
              title: _vm.$t("label.urlInvalidationWarning"),
              text: null,
              subText: null,
              footerNote: null,
              multiLineText: null,
              warningText: _vm.$t("description.urlInvalidationWarning"),
              confirmText: _vm.$t("description.urlInvalidationTips"),
              negativeButtonTitle: _vm.$t("button.no"),
              positiveButtonTitle: _vm.$t("button.yes"),
              onClickNegativeButton: _vm.closeShowUrlInvalidationWarningDialog,
              onClickPositiveButton: _vm.updateBaseInfo
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              maxWidth: 500,
              showDialog: _vm.showNotEditableWarningDialog,
              title: _vm.notEditableWarning.title,
              text: null,
              subText: null,
              footerNote: null,
              multiLineText: null,
              confirmText: _vm.notEditableWarning.message,
              negativeButtonTitle: _vm.$t("button.close"),
              positiveButtonTitle: null,
              onClickNegativeButton: _vm.closeNotEditableWarningDialog,
              onClickPositiveButton: null
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showCreateExtraFileDialog,
              title: _vm.$t("title.agency.extraFileUpload"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.register"),
              onClickNegativeButton: _vm.cancelCreateExtraFile,
              onClickPositiveButton: _vm.createExtraFile,
              targets: _vm.createExtraFileTargets,
              disabled: _vm.isSendData
            }
          }),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditExtraFileDialog,
              title: _vm.$t("title.agency.extraFileEdit"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelExtraFileEdit,
              onClickPositiveButton: _vm.updateExtraFile,
              targets: _vm.editExtraFileTargets,
              disabled: _vm.isSendData
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showDeleteExtraFileDialog,
              title: _vm.$t("title.agency.extraFileDelete"),
              confirmText: _vm.deleteExtraFileText,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.delete"),
              onClickNegativeButton: _vm.cancelExtraFileDelete,
              onClickPositiveButton: _vm.deleteExtraFile
            }
          }),
          _c("SimpleDialog", {
            attrs: {
              showDialog: _vm.showLockoutReleaseDialog,
              title: _vm.lockoutText.title,
              confirmText: _vm.lockoutText.text,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.lockoutText.button,
              onClickNegativeButton: _vm.cancelLockoutRelease,
              onClickPositiveButton: _vm.lockoutRelease
            }
          }),
          _c(
            "v-dialog",
            {
              attrs: {
                width: "50vw",
                height: "50vh",
                "max-width": "50vw",
                "max-height": "50vh"
              },
              model: {
                value: _vm.showImgPreviewDialog,
                callback: function($$v) {
                  _vm.showImgPreviewDialog = $$v
                },
                expression: "showImgPreviewDialog"
              }
            },
            [
              _c(
                "v-container",
                {
                  staticClass: "ma-0",
                  staticStyle: { "background-color": "rgba(255, 255, 255)" },
                  attrs: { fluid: "" }
                },
                [
                  _c(
                    "v-row",
                    [
                      _c("v-img", {
                        staticClass: "mx-auto",
                        staticStyle: {
                          border: "#000 1px solid",
                          "text-align": "center"
                        },
                        attrs: {
                          src: _vm.previewImg,
                          "max-width": "90%",
                          "max-height": "90%",
                          contain: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pt-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            align: "center",
                            width: "30%",
                            "max-width": "300px",
                            color: "next",
                            dark: ""
                          },
                          on: {
                            click: function($event) {
                              _vm.showImgPreviewDialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.close")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("FormsDialog", {
            attrs: {
              showDialog: _vm.showEditNoteDialog,
              title: _vm.$t("header.contractListTable.note"),
              text: null,
              subText: null,
              negativeButtonTitle: _vm.$t("button.cancel"),
              positiveButtonTitle: _vm.$t("button.update"),
              onClickNegativeButton: _vm.cancelEditNote,
              onClickPositiveButton: _vm.updateNote,
              targets: _vm.editNoteTargets
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }