<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout v-show="!isLoading && !isAvailable" column wrap>
      <p class="mt-2">{{ errorMessage }}</p>
      <v-btn
        class="font-weight-black float-right mr-4"
        width="70vw"
        max-width="350px"
        color="next"
        style="font-size:20px"
        dark
        @click="onClickBackHomeButton()"
        >{{ $t('button.backHome') }}</v-btn
      >
    </v-layout>

    <v-layout v-show="!isLoading && isAvailable" column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3 pb-0 mb-0" style="font-size:32px">
            {{ $t('title.agency.contractDetail') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0" style="height: 100%">
          <!-- ステータス -->
          <v-container fill-height>
            <v-row>
              <v-col class="pb-0"> </v-col>
              <v-col class="pb-0">
                <v-btn
                  class="font-weight-black float-right"
                  align="center"
                  width="25vw"
                  max-width="300px"
                  color="next"
                  style="font-size:20px"
                  :dark="isStatusChangeButtonEnabled"
                  :disabled="!isStatusChangeButtonEnabled"
                  @click="onClickStatusChangeButton()"
                  >{{ $t('button.statusChange') }}</v-btn
                >
              </v-col>
            </v-row>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border mt-2"
              style="width: 100%;"
            >
              <tbody>
                <tr v-for="status in statusRecords" :key="status.key">
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{ $t(`header.baseInfoTable.${status.key}`) }}
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td>
                      <v-row v-if="status.value">
                        <v-col
                          :class="checkLockoutStyle()"
                          align-self="center"
                          style="flex:auto; width:auto;"
                        >
                          <p class="mb-0" align="left" style="font-size:14px">
                            {{ status.value }}
                            <template v-if="isLocked">
                              ({{ $t('label.lockout') }})
                            </template>
                          </p>
                        </v-col>
                        <v-col
                          v-if="isEnabledlockoutRelease"
                          style="flex:auto; width:auto;"
                        >
                          <!-- ロックアウト解除ボタン -->
                          <v-btn
                            class="font-weight-black float-right"
                            align="center"
                            color="next"
                            :dark="isEnabledlockoutRelease"
                            :disabled="!isEnabledlockoutRelease"
                            @click="onClickLockoutRelease()"
                            >{{ lockoutText.button }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container>
            <v-row>
              <v-col class="pb-0"> </v-col>
              <v-col class="pb-0">
                <v-btn
                  class="font-weight-black float-right"
                  align="center"
                  width="25vw"
                  max-width="350px"
                  color="next"
                  style="font-size:20px"
                  :dark="isNoteEditButtonEnabled"
                  :disabled="!isNoteEditButtonEnabled"
                  slot="activator"
                  @click="onClickEditNoteButton()"
                  >{{ $t('button.updateMemo') }}</v-btn
                >
              </v-col>
            </v-row>
            <!-- メモ -->
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border mt-2"
              style="width: 100%;"
            >
              <tbody>
                <tr v-for="note in noteRecords" :key="note.key">
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{ $t(`header.baseInfoTable.${note.key}`) }}
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td class="py-4">
                      <p
                        v-for="(value, index) in note.value"
                        :key="index"
                        class="ma-0 pa-0"
                        align="left"
                      >
                        {{ value }}
                      </p>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 mb-0">
          <p class="pb-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.unditableBaseInfo') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container fill-height>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="baseRecord in uneditableLeftBaseRecords"
                  :key="baseRecord.key"
                >
                  <!-- 基本情報(編集不可、左) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${baseRecord.key}`)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td>
                      {{ baseRecord.value }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>

        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="baseRecord in uneditableRightBaseRecords"
                  :key="baseRecord.key"
                >
                  <!-- 基本情報(編集不可、右) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${baseRecord.key}`)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td>
                      {{ baseRecord.value }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <p class="pb-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.editableBaseInfo') }}
          </p>
        </v-col>
        <v-col class="pb-0 mb-0 mr-3">
          <v-btn
            class="font-weight-black float-right"
            align="center"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            :dark="isBaseInfoEditButtonEnabled"
            :disabled="!isBaseInfoEditButtonEnabled"
            slot="activator"
            @click="onClickEditContractButton()"
            >{{ $t('label.editContract') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0">
          <v-container>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="baseRecord in editableLeftBaseRecords"
                  :key="baseRecord.key"
                >
                  <!-- 基本情報(編集可能、左) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${baseRecord.key}`)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td>
                      {{ baseRecord.value }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <v-col class="py-0 pl-0">
          <v-container>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="baseRecord in editableRightBaseRecords"
                  :key="baseRecord.key"
                >
                  <!-- 基本情報(編集可能、右) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${baseRecord.key}`)
                            }}</span
                          >
                          <v-icon
                            v-if="baseRecord.showToCustomer"
                            style="width:24px; height:100%"
                            class="px-0 py-0 float-right my-auto"
                            >mdi-star</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td>
                      <v-row>
                        <v-col
                          align-self="center"
                          style="flex:auto; width:auto;"
                        >
                          <template v-if="baseRecord.key === 'comment'">
                            <p
                              v-for="(value, index) in baseRecord.value"
                              :key="index"
                              class="ma-0 pa-0"
                              align="left"
                              style="font-size:14px;"
                            >
                              {{ value }}
                            </p>
                          </template>
                          <template v-else>
                            <p class="mb-0" align="left" style="font-size:14px">
                              {{ baseRecord.value }}
                            </p>
                          </template>
                        </v-col>
                        <v-col
                          v-if="baseRecord.key === 'extraFile'"
                          style="flex:auto; width:auto;"
                        >
                          <!-- 申込内容以外データアップロードボタン -->
                          <v-btn
                            v-if="baseRecord.key == 'extraFile'"
                            class="mt-1 mb-2 font-weight-black float-right"
                            align="center"
                            color="next"
                            :dark="isExtraFileUploadButtonEnabled"
                            :disabled="!isExtraFileUploadButtonEnabled"
                            @click="onClickExtraFileUploadButton()"
                            >{{ $t('button.otherUpload') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
            <v-col class="px-0 py-3">
              <v-icon style="width:24px; height:100%"
              >mdi-star
              </v-icon>
                {{ $t('description.contractDetailTop.explanation1') }}
            </v-col>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 mb-0">
          <p class="mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.PlanList') }}
          </p>
        </v-col>
      </v-row>
      <v-row style="width:100%;" class="mb-1">
        <v-col>
          <v-data-table
            calculate-widths
            :headers="headers"
            :items="records"
            :hide-default-footer="true"
            class="elevation-1"
            style="width: 100%; display: block;"
            :no-data-text="$t('error.planNotFound')"
          >
            <template v-slot:header.planName="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.planName')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.insurancePeriodText="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.insurancePeriodText')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.earthquakeInsurancePeriodText="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.earthquakeInsurancePeriodText')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.grandTotalPrice="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.grandTotalPrice')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.totalPremium="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.totalPremium')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.paymentTypeName="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.paymentTypeName')
                  }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.message="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.message')
                  }}</span>
                  <span style="font-size:14px;color:#cc0022">{{
                    $t('header.planListTable.messageComment')
                  }}</span>
                </v-col>
                <v-col cols="1" class="mr-3 my-auto">
                  <v-icon>mdi-star</v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:header.comment="{ item }">
              <v-row>
                <v-col>
                  <span style="font-size:14px;color:#696969">{{
                    $t('header.planListTable.comment')
                  }}</span>
                </v-col>
                <v-col cols="1" class="mr-3 my-auto">
                  <v-icon>mdi-star</v-icon>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-bind:class="{
                  selectedPlan: showBackgroundColor(item.id),
                }"
              >
                <td class="v-data-table__divider text-left">
                  {{ item.planName }}
                </td>
                <td class="v-data-table__divider text-left">
                  {{ item.insurancePeriodText }}
                </td>
                <td class="v-data-table__divider text-left">
                  {{ item.earthquakeInsurancePeriodText }}
                </td>
                <td class="v-data-table__divider text-right">
                  {{ item.grandTotalPrice }}
                </td>
                <td class="v-data-table__divider text-right">
                  {{ item.totalPremium }}
                </td>
                <td class="v-data-table__divider text-left">
                  {{ item.paymentTypeName }}
                </td>
                <!-- 必要関連書類の編集ボタン -->
                <td
                  class="v-data-table__divider text-left"
                  style="padding-left:0px; padding-right:0px"
                >
                  <v-row style="margin:auto">
                    <v-col align-self="center" style="width:auto;">
                      <p class="ma-0 pa-0" align="left" style="font-size:14px">
                        {{ item.attachDoc }}
                      </p>
                    </v-col>
                  </v-row>
                  <hr color="#95949a" size="1px" noshade />
                  <v-row :style="showAttachDocBackgroundColor(item.id)">
                    <v-col align-self="center" style="width:auto;">
                      <p
                        v-for="(message, index) in item.message"
                        :key="index"
                        class="ma-0 pa-0"
                        align="left"
                        style="font-size:14px;"
                      >
                        {{ message }}
                      </p>
                    </v-col>
                    <v-col align-self="center" style="flex:auto; width:auto;">
                      <v-btn
                        class="font-weight-black float-right"
                        align="center"
                        color="next"
                        :dark="isEditPlanMessageButtonEnabled"
                        :disabled="!isEditPlanMessageButtonEnabled"
                        @click="onClickEditPlanMessageButton(item.id)"
                        >{{ $t('header.planListTable.edit') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
                <!-- プラン説明の編集ボタン -->
                <td class="text-center__divider">
                  <v-row>
                    <v-col align-self="center" style="flex:auto; width:auto;">
                      <p
                        v-for="(comment, index) in item.comment"
                        :key="index"
                        class="ma-0 pa-0"
                        align="left"
                        style="font-size:14px;"
                      >
                        {{ comment }}
                      </p>
                    </v-col>
                    <v-col align-self="center" style="flex:auto; width:auto;">
                      <v-btn
                        class="font-weight-black float-right"
                        align="center"
                        color="next"
                        :dark="isEditPlanCommentButtonEnabled"
                        :disabled="!isEditPlanCommentButtonEnabled"
                        @click="onClickEditPlanCommentButton(item.id)"
                        >{{ $t('header.planListTable.edit') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <p class="pb-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.agencyInfo') }}
          </p>
        </v-col>
        <v-col class="pb-0 mb-0 mr-3">
          <v-btn
            class="font-weight-black float-right"
            align="center"
            width="70vw"
            max-width="350px"
            color="next"
            style="font-size:20px"
            :dark="isAgencyInfoEditButtonEnabled"
            :disabled="!isAgencyInfoEditButtonEnabled"
            slot="activator"
            @click="onClickEditAgencyButton()"
            >{{ $t('label.editAgency') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container class="pb-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="agencyRecord in leftAgencyRecords"
                  :key="agencyRecord.key"
                >
                  <!-- 代理店情報(左) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${agencyRecord.key}`)
                            }}</span
                          >
                          <v-icon
                            v-if="agencyRecord.showToCustomer"
                            style="width:24px; height:100%"
                            class="px-0 py-0 float-right my-auto"
                            >mdi-star</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <td>
                    <v-row>
                      <v-col align-self="center" style="flex:auto; width:auto;">
                        <p class="mb-0" align="left" style="font-size:14px">
                          {{ agencyRecord.value }}
                        </p>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container class="pb-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="agencyRecord in rightAgencyRecords"
                  :key="agencyRecord.key"
                >
                  <!-- 代理店情報(右) -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text pl-1"
                  >
                    <v-container>
                      <v-row>
                        <v-col>
                          <span style="font-size:14px">
                            {{
                              $t(`header.baseInfoTable.${agencyRecord.key}`)
                            }}</span
                          >
                          <v-icon
                            v-if="agencyRecord.showToCustomer"
                            style="width:24px; height:100%"
                            class="px-0 py-0 float-right my-auto"
                            >mdi-star</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                  <!-- 内容 -->
                  <td>
                    <v-row>
                      <v-col
                        class="py-0"
                        align-self="center"
                        style="flex:auto; width:auto;"
                      >
                        <p class="mb-0" align="left" style="font-size:14px">
                          {{ agencyRecord.value }}
                        </p>
                      </v-col>
                      <template style="flex:auto; width:auto;">
                        <!-- 契約情報反映ボタン -->
                        <v-btn
                          v-if="
                            agencyRecord.key === 'staffName' ||
                              agencyRecord.key === 'agencyName'
                          "
                          class="mr-1 font-weight-black float-right"
                          align="center"
                          color="next"
                          :dark="isReflectContractInfoButtonEnabled"
                          :disabled="!isReflectContractInfoButtonEnabled"
                          @click="
                            onClickReflectButton(
                              agencyRecord.value,
                              $t(`header.baseInfoTable.${agencyRecord.key}`)
                            )
                          "
                          >{{ $t('button.reflectContractInfo') }}
                        </v-btn>
                      </template>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <p class="my-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.salesInfo') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0 pl-0" style="height: 100%">
          <v-container fill-height class="ml-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr v-for="salesRecord in salesRecords" :key="salesRecord.key">
                  <!-- 営業店情報 -->
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{ $t(`header.salesInfoTable.${salesRecord.key}`) }}
                  </td>
                  <template>
                    <td>{{ salesRecord.value }}</td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <!-- ダミー（レイアウト調整用） -->
        <v-col class="pb-0 pl-0" style="height: 100%; visibility:hidden;">
          <v-container fill-height class="ml-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="(dummyRecord, index) in ['dummy0', 'dummy1', 'dummy2']"
                  :key="index"
                >
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  ></td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="py-0">
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container class="py-0">
            <v-row>
              <v-col class="py-0">
                <p class="py-0 mb-0 font-weight-black" style="font-size:20px;">
                  {{ $t('label.sendMessage') }}
                  <v-btn
                    class="font-weight-black float-right"
                    align="center"
                    width="15vw"
                    max-width="350px"
                    color="next"
                    style="font-size:20px"
                    :dark="isNoteEditButtonEnabled"
                    :disabled="!isNoteEditButtonEnabled"
                    slot="activator"
                    @click="onClickSendMessageButton()"
                    >{{ $t('button.view') }}</v-btn
                  >
                </p>
              </v-col>
            </v-row>
            <!-- お客さまへ送付するSMS/メール本文 -->
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border mt-2"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="sendMessage in sendMessageRecords"
                  :key="sendMessage.key"
                >
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{ $t(`description.sendMessageTitle`) }}
                  </td>
                  <!-- 内容 -->
                  <template>
                    <td class="py-4">
                      <p
                        v-for="(value, index) in sendMessage.value"
                        :key="index"
                        class="ma-0 pa-0"
                        align="left"
                      >
                        {{ value }}
                      </p>
                    </td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <!-- ダミー（レイアウト調整用） -->
        <v-col class="py-0 pl-0" style="height: 100%; visibility:hidden;">
          <v-container fill-height class="ml-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr v-for="(dummyRecord, index) in ['dummy0']" :key="index">
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  ></td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <p class="pb-0 mb-0 font-weight-black" style="font-size:20px;">
            {{ $t('label.contractorConfirmResult') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0 pl-0" style="height: 100%">
          <v-container fill-height>
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="confirmRecord in leftConfirmRecords"
                  :key="confirmRecord.key"
                >
                  <!-- お客さま確認情報(左) -->
                  <td
                    v-bind:class="{
                      next: false,
                      'white--text': false,
                    }"
                    class="text-left .v_base_table_with_border base_td_category_text"
                  >
                    {{ $t(`header.confirmInfoTable.${confirmRecord.key}`) }}
                  </td>
                  <template v-if="confirmRecord.key === 'insuredUpload'">
                    <td>
                      {{
                        showCustomerUploads
                          ? doesInsuredUploadExist(confirmRecord.value)
                            ? $t('button.insuredUpload.exist')
                            : $t('button.insuredUpload.notExist')
                          : ''
                      }}
                    </td>
                  </template>
                  <template v-else>
                    <td>{{ confirmRecord.value }}</td>
                  </template>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
        <!-- ダミー（レイアウト調整用） -->
        <v-col class="py-0 pl-0" style="height: 100%; visibility:hidden;">
          <v-container fill-height class="ml-0">
            <v-simple-table
              class="elevation-1 v_base_table_fill_width v_base_table_with_border"
              style="width: 100%;"
            >
              <tbody>
                <tr
                  v-for="(dummyRecord, index) in ['dummy0', 'dummy1', 'dummy2']"
                  :key="index"
                >
                  <td
                    class="text-left .v_base_table_with_border base_td_category_text"
                  ></td>
                  <td></td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-container>
        </v-col>
      </v-row>
      <v-row class="pt-10">
        <v-col style="text-align: center">
          <!-- SMS/メール送信 -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            max-width="300px"
            color="next"
            style="font-size:20px"
            :dark="!isSendMsgButtonDisabled"
            :disabled="isSendMsgButtonDisabled"
            @click="choiceDialog()"
            >{{ $t('button.sendMsg') }}</v-btn
          >
        </v-col>
        <v-col style="text-align: center">
          <!-- 重要事項等説明書印刷／ダウンロード -->
          <v-btn
            class="font-weight-black"
            align="center"
            width="25vw"
            min-width="280px"
            max-width="300px"
            color="next"
            style="font-size:20px"
            :dark="isFilePrintAndDownloadButtonEnabled"
            :disabled="!isFilePrintAndDownloadButtonEnabled"
            @click="onClickFilePrintAndDownloadButton()"
            >{{ $t('button.filePrintAndDownload') }}</v-btn
          >
        </v-col>
      </v-row>
      <ReflectContractInfoDialog
        @onSuccess="onSuccessReflectLoginUserInfo"
        ref="reflectContractInfoDialog"
      ></ReflectContractInfoDialog>
      <SendingConfirmDialog
        @onSuccess="onSuccessSendingConfirm"
        ref="sendingConfirmDialog"
      ></SendingConfirmDialog>
      <EditExtraFileDialog
        ref="editExtraFileDialog"
        :files="extraFilelist"
        :onClickCreate="onClickExtraFileCreate"
        :onClickPreview="onClickExtraFilePreview"
        :onClickEdit="onClickExtraFileEdit"
        :onClickDelete="onClickExtraFileDelete"
      />
      <FileListDialog ref="fileListDialog" />
      <SendMessageDialog
        @onSuccess="onSuccessSendMessage"
        :smsMessageRecords="smsMessageRecords"
        :mailMessageRecords="mailMessageRecords"
        ref="sendMessageDialog"
      />
      <SelectAndEditSendMessageDialog
        @onSuccess="onSuccessSelectAndEditSendMessage"
        ref="selectAndEditSendMessageDialog"
      />
      <ErrorDialog ref="errorDialog" />
      <CompletedDialog ref="completedDialog" />
      <!-- 基本情報編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditBaseInfoDialog"
        :title="$t('label.editContract')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditBaseInfo"
        :onClickPositiveButton="updateBaseInfoConfirm"
        :targets="editBaseInfoTargets"
      />
      <!-- 代理店情報編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditAgencyInfoDialog"
        :title="$t('label.editAgency')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditAgencyInfo"
        :onClickPositiveButton="updateAgencyInfo"
        :targets="editAgencyInfoTargets"
      />
      <!-- 必要書類関連情報編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditPlanMessageDialog"
        :title="$t('label.editPlanMessage')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditPlanMessage"
        :onClickPositiveButton="updatePlanMessage"
        :targets="editPlanMessageTargets"
      />
      <!-- プラン説明編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditPlanCommentDialog"
        :title="$t('label.editPlanComment')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditPlanComment"
        :onClickPositiveButton="updatePlanComment"
        :targets="editPlanCommentTargets"
      />
      <!-- 送信方法・認証方法選択ダイアログ -->
      <FormsDialog
        :showDialog="showSelectSendMethodAndAuthMethodDialog"
        :title="selectSendMethodAndAuthMethodDialogTitle"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.confirm')"
        :onClickNegativeButton="cancelSelectSendMethodAndAuthMethodDialog"
        :onClickPositiveButton="sendingConfirm"
        :targets="selectSendMethodAndAuthMethodTargets"
      />
      <!-- SMS/メール再送信確認ダイアログダイアログ -->
      <SimpleDialog
        :showDialog="showResendingConfirmDialog"
        :title="$t('label.resendingConfirm')"
        :text="null"
        :subText="null"
        :footerNote="null"
        :multiLineText="null"
        :confirmText="$t('description.sendMailAndSms.resendingConfirmText')"
        :negativeButtonTitle="$t('button.no')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="cancelResendingConfirmDialog"
        :onClickPositiveButton="resendingConfirm"
      />
      <!-- 案件削除確認ダイアログ -->
      <DeleteDialog
        :title="$t('title.agency.contractDeleteConfirm')"
        @onSuccess="onSuccessContractDeleteConfirm"
        ref="contractDeleteConfirmDialog"
      />
      <!-- ステータス変更ダイアログ -->
      <FormsDialog
        :showDialog="showStatusChangeDialog"
        :title="$t('title.agency.statusChange')"
        :text="statusChangeDialogText"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.change')"
        :onClickNegativeButton="cancelStatusChange"
        :onClickPositiveButton="confirmStatusChange"
        :targets="editStatusTargets"
      />
      <!-- ステータス変更確認ダイアログ -->
      <FormsDialog
        :showDialog="showStatusChangeConfirmDialog"
        :title="$t('title.agency.statusChangeConfirm')"
        :text="null"
        :subText="null"
        :multiLineText="statusChangeConfirmDialogConfirmText"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.change')"
        :onClickNegativeButton="cancelStatusChangeConfirm"
        :onClickPositiveButton="updateStatus"
        :targets="editStatusChangeConfirmTargets"
        :disabled="false"
      />
      <!-- 送信本文プレビュー -->
      <SimpleDialog
        :maxWidth="900"
        :showDialog="showSendPreviewDialog"
        :title="$t('label.sendPreview')"
        :subMessage="noticeOfModificationContent"
        :footerNote="$t('description.messageContent.footerNote')"
        :multiLineText="sendMessageForPreview"
        :confirmText="null"
        :negativeButtonTitle="$t('button.back')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="closeSendPreviewDialog"
        :onClickPositiveButton="saveSendMessage"
      />
      <!-- URL無効化警告 -->
      <SimpleDialog
        :showDialog="showUrlInvalidationWarningDialog"
        :title="$t('label.urlInvalidationWarning')"
        :text="null"
        :subText="null"
        :footerNote="null"
        :multiLineText="null"
        :warningText="$t('description.urlInvalidationWarning')"
        :confirmText="$t('description.urlInvalidationTips')"
        :negativeButtonTitle="$t('button.no')"
        :positiveButtonTitle="$t('button.yes')"
        :onClickNegativeButton="closeShowUrlInvalidationWarningDialog"
        :onClickPositiveButton="updateBaseInfo"
      />
      <!-- 編集不可警告 -->
      <SimpleDialog
        :maxWidth="500"
        :showDialog="showNotEditableWarningDialog"
        :title="notEditableWarning.title"
        :text="null"
        :subText="null"
        :footerNote="null"
        :multiLineText="null"
        :confirmText="notEditableWarning.message"
        :negativeButtonTitle="$t('button.close')"
        :positiveButtonTitle="null"
        :onClickNegativeButton="closeNotEditableWarningDialog"
        :onClickPositiveButton="null"
      />
      <!-- 申込内容以外データ登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateExtraFileDialog"
        :title="$t('title.agency.extraFileUpload')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreateExtraFile"
        :onClickPositiveButton="createExtraFile"
        :targets="createExtraFileTargets"
        :disabled="isSendData"
      />
      <!-- 申込内容以外データ編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditExtraFileDialog"
        :title="$t('title.agency.extraFileEdit')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelExtraFileEdit"
        :onClickPositiveButton="updateExtraFile"
        :targets="editExtraFileTargets"
        :disabled="isSendData"
      />
      <!-- 申込内容以外データ削除確認 -->
      <SimpleDialog
        :showDialog="showDeleteExtraFileDialog"
        :title="$t('title.agency.extraFileDelete')"
        :confirmText="deleteExtraFileText"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.delete')"
        :onClickNegativeButton="cancelExtraFileDelete"
        :onClickPositiveButton="deleteExtraFile"
      />
      <!-- ロックアウト解除確認 -->
      <SimpleDialog
        :showDialog="showLockoutReleaseDialog"
        :title="lockoutText.title"
        :confirmText="lockoutText.text"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="lockoutText.button"
        :onClickNegativeButton="cancelLockoutRelease"
        :onClickPositiveButton="lockoutRelease"
      />
      <!-- 画像のモーダル表示 -->
      <v-dialog
        v-model="showImgPreviewDialog"
        width="50vw"
        height="50vh"
        max-width="50vw"
        max-height="50vh"
      >
        <v-container
          fluid
          class="ma-0"
          style="background-color:rgba(255, 255, 255);"
        >
          <v-row>
            <v-img
              class="mx-auto"
              :src="previewImg"
              max-width="90%"
              max-height="90%"
              :contain="true"
              style="border: #000 1px solid;; text-align:center"
            />
          </v-row>
          <v-row class="pt-2">
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="30%"
              max-width="300px"
              color="next"
              style="font-size:20px"
              dark
              @click="showImgPreviewDialog = false"
              >{{ $t('button.close') }}</v-btn
            >
          </v-row>
        </v-container>
      </v-dialog>
      <!-- メモ編集ダイアログ -->
      <FormsDialog
        :showDialog="showEditNoteDialog"
        :title="$t('header.contractListTable.note')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.update')"
        :onClickNegativeButton="cancelEditNote"
        :onClickPositiveButton="updateNote"
        :targets="editNoteTargets"
      />
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import {
  ErrorCode,
  PlanListTableHeader,
  ContractStatusDisplay,
  ContractStatus,
  ContractStatusChangeEvent,
  MaxLength,
  SentSms,
  OfficialUrl,
  SenderFlg,
  AuthType,
  uploadLimitFileSize,
  ContractorLockoutStatus,
  TextStyle,
  RightOfPledgeDisplay,
} from '@/lib/const';
import { Status } from '@/lib/status';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import DeleteDialog from '@/components/organisms/agency/DeleteDialog';
import { Role } from '@/lib/role';
import ReflectContractInfoDialog from '@/components/organisms/agency/ReflectContractInfoDialog';
import SendingConfirmDialog from '@/components/organisms/agency/SendingConfirmDialog';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import EditExtraFileDialog from '@/components/organisms/agency/EditExtraFileDialog';
import FileListDialog from '@/components/organisms/agency/FileListDialog';
import SendMessageDialog from '@/components/organisms/agency/SendMessageDialog';
import SelectAndEditSendMessageDialog from '@/components/organisms/agency/SelectAndEditSendMessageDialog';
import SimpleDialog from '@/components/organisms/agency/SimpleDialog';
import {
  getContractDetail,
  updateContractDetail,
  deleteContract,
  putContractStatus,
  updatePlanDetail,
  sendMessage,
  registerExtraPictures,
  editExtraPictures,
  deleteExtraPictures,
  lockoutRelease,
} from '@/apis/agency/contracts';
import {
  getSendTextManagements,
  updateSendTextManagement,
} from '@/apis/agency/sendTextManagements';
import { getExtraPictureList, getExtraPicture } from '@/apis/agency/contracts';
import { getSendTemplateTextManagements } from '@/apis/agency/sendTemplateTextManagements.js';
import {
  convertBlobToBase64,
  getImgWidthAndHeight,
  replaceUnspecifiedInfo,
  splitMessageLine,
  convertDateOfBirth,
  convertDate,
} from '@/lib/commonUtil';

export default {
  name: 'ContractDetail',
  components: {
    ErrorDialog,
    FormsDialog,
    DeleteDialog,
    ReflectContractInfoDialog,
    SendingConfirmDialog,
    CompletedDialog,
    EditExtraFileDialog,
    FileListDialog,
    SendMessageDialog,
    SelectAndEditSendMessageDialog,
    SimpleDialog,
  },

  data: vm => ({
    headers: PlanListTableHeader,

    // エラーメッセージ
    errorMessage: '',

    // プラン情報
    records: [
      {
        id: null,
        planName: null,
        insurancePeriodText: null,
        earthquakeInsurancePeriodText: null,
        grandTotalPrice: null,
        totalPremium: null,
        paymentTypeName: null,
        attachDoc: null,
        message: null,
        comment: null,
      },
    ],
    statusRecords: [{ key: 'status', value: null }],
    noteRecords: [{ key: 'note', value: null }],
    sendMessageRecords: [{ key: 'sendMessage', value: null }],
    // 基本情報(編集不可、左)
    uneditableLeftBaseRecords: [
      { key: 'acceptanceType', value: null },
      { key: 'policyNumber', value: null, showToCustomer: true },
      { key: 'previousPolicyNumber', value: null, showToCustomer: true },
      { key: 'printingSerialNumber', value: null, showToCustomer: true },
      { key: 'insuranceTypeCode', value: null, showToCustomer: true },
      { key: 'userType', value: null, showToCustomer: true },
      { key: 'fullName', value: null, showToCustomer: true },
      { key: 'fullNameKana', value: null, showToCustomer: true },
      { key: 'startDate', value: null, showToCustomer: true },
    ],
    // 基本情報(編集不可、右)
    uneditableRightBaseRecords: [
      { key: 'contractUserFullAddress', value: null, showToCustomer: true },
      { key: 'contractUserPostalCode', value: null, showToCustomer: true },
      { key: 'insuranceBuildingFullAddress', value: null, showToCustomer: true },
      { key: 'insuranceObject1', value: null, showToCustomer: true },
      { key: 'paymentTransferTypeName', value: null, showToCustomer: true },
      { key: 'organizationCode', value: null, showToCustomer: true },
      { key: 'organizationName', value: null },
      { key: 'rightOfPledge', value: null },
    ],
    // 基本情報(編集可能、左)
    editableLeftBaseRecords: [
      { key: 'birthday', value: null, showToCustomer: true },
      { key: 'mobileNumber', value: null },
      { key: 'mail', value: null },
    ],
    // 基本情報(編集可能、右)
    editableRightBaseRecords: [
      { key: 'extraFile', value: null, showToCustomer: true },
      { key: 'comment', value: null, showToCustomer: true },
    ],
    // 代理店情報(左)
    leftAgencyRecords: [
      { key: 'agencyCode', value: null },
      { key: 'agencyCodeUser', value: null },
    ],
    // 代理店情報(右)
    rightAgencyRecords: [
      { key: 'agencyName', value: null, showToCustomer: true },
      { key: 'staffName', value: null, showToCustomer: true },
      { key: 'agencyPhoneNumber', value: null, showToCustomer: true },
    ],
    // 営業店情報
    salesRecords: [
      { key: 'salesCode', value: null },
      { key: 'salesDepartmentName', value: null },
      { key: 'salesSectionName', value: null },
    ],
    // お客さま確認結果情報(左)
    leftConfirmRecords: [
      { key: 'completionDate', value: null },
      { key: 'selectedPlan', value: null },
      { key: 'insuredUpload', value: null },
    ],
    // SMS送信本文
    smsMessageRecords: [
      { key: 'proposingSmsBody', value: null },
      { key: 'concludedSmsBody', value: null },
    ],
    // メール送信本文
    mailMessageRecords: [
      { key: 'proposingEmailBody', value: null },
      { key: 'concludedEmailBody', value: null },
    ],
    //  SMS/メール送信本文置換用契約情報
    contractInfoForReplace: {},

    // ステータス編集データ (デフォルト)
    editStatusDefaultTargets: [
      {
        value: null,
        targetName: 'status',
        type: 'select',
        label: vm.$t('header.baseInfoTable.status'),
        text: null,
        rule: 'selectRequired',
        key: 'status',
        listName: 'statusList',
      },
    ],

    // ステータス編集データ
    editStatusTargets: [],

    // ステータス変更確認編集データ (デフォルト)
    editStatusChangeConfirmDefaultTargets: [
      {
        value: null,
        targetName: 'needsNotify',
        type: 'radio',
        label: null,
        text: null,
        rule: 'selectRequired',
        key: 'needsNotify',
        list: [
          {
            text: vm.$t('label.needsNotify'),
            value: true,
          },
          {
            text: vm.$t('label.notNeedsNotify'),
            value: false,
          },
        ],
      },
    ],

    // ステータス変更確認編集データ
    editStatusChangeConfirmTargets: [],

    // メモ編集ダイアログが表示されているかどうか
    showEditNoteDialog: false,

    // メモ編集データ
    editNoteTargets: [],

    // メモデータ (デフォルト)
    editNoteDefaultTargets: [
      {
        value: null,
        targetName: 'note',
        type: 'textarea',
        label: vm.$t('header.contractListTable.note'),
        text: null,
        rule: `max:${MaxLength.ContractNote}`,
        key: 'note',
        max: MaxLength.ContractNote,
      },
    ],

    // 基本情報編集データ (デフォルト)
    editBaseInfoDefaultTargets: [
      {
        value: null,
        targetName: 'birthday',
        type: 'date',
        label: vm.$t('header.baseInfoTable.birthday'),
        text: null,
        rule: null,
        key: 'birthday',
      },
      {
        value: null,
        targetName: 'mobileNumber',
        type: 'phone',
        label: vm.$t('header.baseInfoTable.mobileNumber'),
        text: null,
        rule: `noSpace|telNumberLength`,
        key: 'mobileNumber',
      },
      {
        value: null,
        targetName: 'mail',
        type: 'text',
        label: vm.$t('header.baseInfoTable.mail'),
        text: null,
        rule: `noSpace|pattern:mailAddress|max:${MaxLength.MailAddress}`,
        key: 'mail',
      },
      {
        value: null,
        targetName: 'comment',
        type: 'textarea',
        label: vm.$t('header.baseInfoTable.comment'),
        text: null,
        rule: `max:${MaxLength.AgencyComment}`,
        key: 'comment',
        max: MaxLength.AgencyComment,
      },
    ],

    // 基本情報編集データ
    editBaseInfoTargets: [],

    // 代理店情報編集データ (デフォルト)
    editAgencyInfoDefaultTargets: [
      {
        value: null,
        targetName: 'name',
        type: 'text',
        label: vm.$t('header.baseInfoTable.agencyName'),
        text: null,
        rule: `required|max:${MaxLength.AgencyName}`,
        key: 'name',
      },
      {
        value: null,
        targetName: 'staffName',
        type: 'text',
        label: vm.$t('header.baseInfoTable.staffName'),
        text: null,
        rule: `required|max:${MaxLength.ChargePersonName}`,
        key: 'staffName',
      },
      {
        value: null,
        targetName: 'phoneNumber',
        type: 'phone',
        label: vm.$t('header.baseInfoTable.agencyPhoneNumber'),
        text: null,
        rule: 'noSpace|required|telNumberLength',
        key: 'phoneNumber',
      },
    ],

    // 代理店情報編集データ
    editAgencyInfoTargets: [],

    // 必要書類関連情報編集データ (デフォルト)
    editPlanMessageDefaultTargets: [
      {
        value: null,
        targetName: 'message',
        type: 'textarea',
        label: vm.$t('header.planListTable.message'),
        text: null,
        rule: `max:${MaxLength.PlanMessage}`,
        key: 'message',
        max: MaxLength.PlanMessage,
      },
    ],

    // 必要書類関連情報編集データ
    editPlanMessageTargets: [],

    // プラン説明編集データ (デフォルト)
    editPlanCommentDefaultTargets: [
      {
        value: null,
        targetName: 'comment',
        type: 'textarea',
        label: vm.$t('header.planListTable.comment'),
        text: null,
        rule: `max:${MaxLength.PlanComment}`,
        key: 'comment',
        max: MaxLength.PlanComment,
      },
    ],

    // プラン説明編集データ
    editPlanCommentTargets: [],

    // ステータスの選択肢
    statusList: [],

    // 変更後のステータス
    newStatus: null,

    // ステータス変更イベント
    statusChangeEvent: null,

    // ステータス変更イベントかどうか
    isChangeStatusEventForOnce: false,

    // ステータス変更ダイアログテキスト
    statusChangeDialogText: '',

    // 編集中のプランID
    editPlanId: null,

    // 契約データ
    contract: {},

    // メモ編集ボタンが有効であるかどうか
    isNoteEditButtonEnabled: false,

    // 基本情報編集ボタンが有効であるかどうか
    isBaseInfoEditButtonEnabled: false,

    // 代理店情報編集ボタンが有効であるかどうか
    isAgencyInfoEditButtonEnabled: false,

    // 契約情報を反映ボタンが有効であるかどうか
    isReflectContractInfoButtonEnabled: false,

    // SMS(メール)送信本文編集ボタンが有効であるかどうか
    isSelectAndEditSmsMailMessageBodyButtonEnabled: false,

    // 必要書類関連情報編集ボタンが有効であるかどうか
    isEditPlanMessageButtonEnabled: false,

    // プラン説明編集ボタンが有効であるかどうか
    isEditPlanCommentButtonEnabled: false,

    // 重要事項等説明書印刷／ダウンロードボタンが有効であるかどうか
    isFilePrintAndDownloadButtonEnabled: false,

    // ステータス変更ボタンが有効であるかどうか
    isStatusChangeButtonEnabled: false,

    // SMS/メール送信ボタンが無効であるかどうか
    isSendMsgButtonDisabled: false,

    // 申込内容以外データアップロードボタンが無効であるかどうか
    isExtraFileUploadButtonEnabled: false,

    // 送信先情報
    destinationInfo: {},

    // ステータス変更ダイアログが表示されているかどうか
    showStatusChangeDialog: false,

    // ステータス変更確認ダイアログが表示されているかどうか
    showStatusChangeConfirmDialog: false,

    // ステータス変更確認ダイアログの確認テキスト
    statusChangeConfirmDialogConfirmText: '',

    // SMS/メール送信方法・認証方法選択ダイアログが表示されているかどうか
    showSelectSendMethodAndAuthMethodDialog: false,

    // SMS/メール送信方法・認証方法選択ダイアログのタイトル
    selectSendMethodAndAuthMethodDialogTitle: '',

    // SMS/メール再送信可否確認ダイアログが表示されているかどうか
    showResendingConfirmDialog: false,

    // SMS/メール再送信ダイアログの確認テキスト
    resendingConfirmDialogText: '',

    // SMS/メール送信方法・認証方法選択データ (デフォルト)
    selectSendMethodAndAuthMethodDefaultTargets: [
      {
        value: [],
        targetName: 'sendFlg',
        type: 'radio',
        label: vm.$t('description.sendMailAndSms.selectMethodText'),
        text: null,
        rule: 'selectRequired',
        key: 'sendFlg',
        list: [
          {
            value: SenderFlg.Mail,
            text: vm.$t('label.mail'),
          },
          {
            value: SenderFlg.Sms,
            text: vm.$t('label.sms'),
          },
        ],
      },
      {
        value: null,
        targetName: 'authFlg',
        type: 'radio',
        label: vm.$t('description.sendMailAndSms.selectAuthText'),
        text: null,
        rule: 'selectRequired',
        key: 'authFlg',
        list: [
          {
            text: vm.$t('label.policyNumber'),
            value: AuthType.policyNumber,
          },
          {
            text: vm.$t('label.dateOfBirth'),
            value: AuthType.dateOfBirth,
          },
        ],
      },
    ],
    // SMS/メール送信方法・認証方法選択データ
    selectSendMethodAndAuthMethodTargets: [],

    // ローディング中であるかどうか
    isLoading: true,

    // 有効であるかどうか
    isAvailable: true,

    // 基本情報編集ダイアログが表示されているかどうか
    showEditBaseInfoDialog: false,

    // 代理店情報編集ダイアログが表示されているかどうか
    showEditAgencyInfoDialog: false,

    // 必要書類関連情報編集ダイアログが表示されているかどうか
    showEditPlanMessageDialog: false,

    // プラン説明編集ダイアログが表示されているかどうか
    showEditPlanCommentDialog: false,

    // プランごとの必要書類関連情報入力の最大文字数
    planMessageMaxLength: MaxLength.PlanMessage,

    // プランごとの説明入力の最大文字数
    planCommentMaxLength: MaxLength.PlanComment,

    // SMS・メール送信本文（保存用）
    sendMessageForSave: {},

    // SMS・メール送信本文の更新対象レコード
    targetSendTextManagementId: null,

    // SMS・メール送信本文（プレビュー用）
    sendMessageForPreview: null,

    // SMSの送信件数
    textForNumberOfTransmissions: null,

    // SMS送信後の料金表示
    textForSmsTransmissionFee: null,

    // 変更内容の注意書き
    noticeOfModificationContent: null,

    // 送信プレビューダイアログが表示されているかどうか
    showSendPreviewDialog: false,

    // URL無効化する警告ダイアログが表示されているかどうか
    showUrlInvalidationWarningDialog: false,

    // 更新予定の基本情報
    updateBaseInfoCondition: {},

    // 編集不可警告ダイアログが表示されているかどうか
    showNotEditableWarningDialog: false,

    // 編集可否フラグ
    notEditableFlg: false,

    // 編集不可のタイトルとメッセージ文
    notEditableWarning: {
      title: null,
      message: null,
    },

    // 送信方法
    isMail: false,
    isSms: false,

    // 申込内容以外データ一覧
    extraFilelist: [],

    // 申込内容以外データプレビュー
    showImgPreviewDialog: false,
    previewImg: null,

    // 申込内容以外データ登録情報
    showCreateExtraFileDialog: false,
    fileNameList: [],
    createExtraFileDefaultTargets: [
      {
        value: null,
        targetName: 'file',
        type: 'file',
        accept:
          'image/jpeg,image/png,image/gif,application/pdf,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation',
        acceptText: vm.$t('label.uploadableFileExtensions'),
        limitFileSizeText: vm.$t('label.uploadLimitFileSize'),
        label: null,
        text: null,
        rule: `selectRequired|maxFileName|maxSize:${uploadLimitFileSize}|checkFileNameExists:{fileNameList}`,
        key: 'file',
      },
      {
        value: null,
        targetName: 'comment',
        type: 'textarea',
        label: vm.$t('header.ExtraFileListHeaders.comment'),
        text: null,
        rule: `max:${MaxLength.ExtraFileComment}`,
        key: 'comment',
        max: MaxLength.ExtraFileComment,
      },
    ],
    createExtraFileTargets: [],

    // 申込内容以外データ編集情報
    showEditExtraFileDialog: false,
    editExtraFileDefaultTargets: [
      {
        value: null,
        targetName: 'comment',
        type: 'textarea',
        label: vm.$t('header.ExtraFileListHeaders.comment'),
        text: null,
        rule: `max:${MaxLength.ExtraFileComment}`,
        key: 'comment',
        max: MaxLength.ExtraFileComment,
      },
    ],
    editExtraFileTargets: [],
    editExtraFileId: null,

    // データ送信中フラグ
    isSendData: false,

    // 申込内容以外データ削除情報
    showDeleteExtraFileDialog: false,
    deleteExtraFileId: null,
    deleteExtraFileText: null,

    showLockoutReleaseDialog: false,
    // 案件ロック状態
    isLocked: false,
    // ロック解除ボタン表示状態
    isEnabledlockoutRelease: false,
    // ロックアウトテキスト
    lockoutText: {},

    showCustomerUploads: false,
  }),
  async mounted() {
    // 案件詳細の取得
    await this.fetchContractDetail();
  },
  computed: {
    insuranceObjectName1() {
      const planPayout = this.contract.plans.find(plan => plan.number === 1).planPayout;
      return /[1-9１-９]/.test(planPayout.buildingBasicPrice) ? this.$t('label.insuranceObject.building') : this.$t('label.insuranceObject.householdProperty');
    },
    insuranceObjectName2() {
      const planPayout = this.contract.plans.find(plan => plan.number === 1).planPayout;
      return [planPayout.buildingBasicPrice, planPayout.houseItemBasicPrice].every(price => /[1-9１-９]/.test(price)) ? this.$t('label.insuranceObject.householdProperty') : null
    },
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('ui', ['setLoading']),

    // 権限を有しているかどうか
    hasRole(buttonName) {
      return Role.isButtonEnabled(this.userDetail(), buttonName, {
        officeId: this.contract.agency.id,
        userId: this.contract.userId,
      });
    },

    // お客さま提出書類が存在するかどうか
    doesInsuredUploadExist(insuredUploads) {
      return insuredUploads && insuredUploads.length > 0;
    },

    // 案件詳細の取り込み
    async fetchContractDetail() {
      const contractDetail = await getContractDetail(
        this.$route.params['contractId']
      ).catch(() => {
        this.$set(
          this,
          'errorMessage',
          this.$t('description.contractNotFound')
        );
        this.$set(this, 'isAvailable', false);
      });
      this.$set(this, 'isLoading', false);

      // 案件一覧が取得できなかった場合、以降の処理を中止する
      if (!contractDetail) return;

      // 特定のステータスの場合、案件情報を編集不可にする
      if (
        contractDetail.status === ContractStatus.Processing ||
        contractDetail.status === ContractStatus.Waiting
      ) {
        this.showCustomerUploads = false;
        this.$set(this, 'notEditableFlg', false);
        this.$set(this.notEditableWarning, 'message', null);
      } else {
        this.showCustomerUploads = true;
        this.$set(this, 'notEditableFlg', true);
        this.$set(
          this.notEditableWarning,
          'message',
          this.$t('description.notEditableWarning', {
            status: ContractStatusDisplay[contractDetail.status],
          })
        );
      }

      this.$set(this, 'contract', contractDetail);

      // メモ編集ボタンの有効状態をセットする
      this.$set(this, 'isNoteEditButtonEnabled', this.hasRole('noteEdit'));

      //  SMS/メール送信本文置換用契約情報
      this.$set(this, 'contractInfoForReplace', {
        policyNumber: contractDetail.policyNumber,
        fullNameKanji: contractDetail.contractUser.fullName,
        insuranceName: contractDetail.insuranceType.name,
        startDate: convertDate(contractDetail.startDate),
        lastAccessibleDay: moment(contractDetail.startDate)
          .add(-1, 'days')
          .format('YYYY年M月D日'),
        dueDate: moment(contractDetail.startDate)
          .add(3, 'months')
          .format('YYYY年M月D日'),
        insuranceLocation: contractDetail.insuranceBuilding.fullAddress
          ? this.$t('description.messageContent.insuranceLocation', {
              insuranceLocation: contractDetail.insuranceBuilding.fullAddress,
            })
          : this.$t('description.messageContent.contractorAddress', {
              contractorAddress: contractDetail.contractUser.fullAddress,
            }),
        contractUrl:
          OfficialUrl.ContractUrlForCountingBytes +
          this.$t('description.messageContent.dumyContractUrlTokenInfo'),
        modificationContent: this.$t(
          'description.messageContent.dumyModificationContent'
        ),
        agencyName: contractDetail.contractAgency.name
          ? contractDetail.contractAgency.name
          : '',
        chargePersonName: contractDetail.contractAgency.staffName
          ? contractDetail.contractAgency.staffName
          : '',
        agencyPhoneNumber: contractDetail.contractAgency.phoneNumber
            ? contractDetail.contractAgency.phoneNumber
            : '',
        officialWebSiteUrl: OfficialUrl.OfficialWebSite,
        officialLineUrl: OfficialUrl.OfficialLineUrl,
      });

      // 基本情報編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isBaseInfoEditButtonEnabled',
        this.hasRole('baseInfoEdit')
      );

      // 代理店情報編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isAgencyInfoEditButtonEnabled',
        this.hasRole('agencyInfoEdit')
      );

      // 契約情報を反映ボタンの有効状態をセットする
      this.$set(
        this,
        'isReflectContractInfoButtonEnabled',
        this.hasRole('reflectContractInfo')
      );

      // SMS(メール)送信本文編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isSelectAndEditSmsMailMessageBodyButtonEnabled',
        this.hasRole('selectAndEditSmsMailMessageBody')
      );

      // 必要書類関連情報編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isEditPlanMessageButtonEnabled',
        this.hasRole('editPlanMessage')
      );

      // プラン説明編集ボタンの有効状態をセットする
      this.$set(
        this,
        'isEditPlanCommentButtonEnabled',
        this.hasRole('editPlanComment')
      );

      // 重要事項等説明書印刷／ダウンロードボタンの有効状態をセットする
      this.$set(
        this,
        'isFilePrintAndDownloadButtonEnabled',
        this.hasRole('filePrintAndDownload')
      );

      // 申込内容以外データアップロードボタンの有効状態をセットする
      this.$set(
        this,
        'isExtraFileUploadButtonEnabled',
        this.hasRole('extraFileUpload')
      );

      // ステータス変更ボタンの有効状態をセットする
      if (this.contract.status == ContractStatus.Withdrawal) {
        // ステータスが「取り下げ」の場合はステータス変更ボタンを無効にする
        this.$set(this, 'isStatusChangeButtonEnabled', false);
      } else {
        this.$set(
          this,
          'isStatusChangeButtonEnabled',
          this.hasRole('statusChange') &&
            // 変更可能なステータスが存在する場合
            Status.getChoices(this.contract.status).length > 0
        );
      }

      // ロックアウト状態をセットする
      this.$set(
        this,
        'isLocked',
        this.contract.lockoutStatus === ContractorLockoutStatus.Locked
      );

      // ロックアウト解除の有効状態をセットする
      this.$set(
        this,
        'isEnabledlockoutRelease',
        this.contract.lockoutStatus === ContractorLockoutStatus.Locked ||
          this.contract.lockoutStatus === ContractorLockoutStatus.MaximumReSent
      );
      // ロックアウト文言の出し分け
      if (this.isEnabledlockoutRelease) {
        this.$set(
          this,
          'lockoutText',
          this.contract.lockoutStatus === ContractorLockoutStatus.Locked
            ? this.$t('description.lockoutRelease')
            : this.$t('description.authCountReset')
        );
      }

      const extraFilelist = await getExtraPictureList(
        contractDetail.id
      );

      // プランの表示値をマッピング
      const records = this.mapPlanInfo(contractDetail.plans);
      const statusRecords = [
        {
          key: 'status',
          value: ContractStatusDisplay[contractDetail.status],
        },
      ];
      const noteRecords = [
        {
          key: 'note',
          value: splitMessageLine(contractDetail.note),
        },
      ];
      const sendMessageRecords = [
        {
          key: 'sendMesssage',
          value: splitMessageLine(this.$t('description.sendMessage')),
        },
      ];
      // 基本情報の表示値をマッピング
      const uneditableLeftBaseRecords = [
        {
          key: 'acceptanceType',
          value:
          contractDetail.acceptanceType,
        },
        {
          key: 'policyNumber',
          value: contractDetail.policyNumber,
          showToCustomer: true,
        },
        {
          key: 'previousPolicyNumber',
          value: contractDetail.previousPolicyNumber,
          showToCustomer: true,
        },
        {
          key: 'printingSerialNumber',
          value: contractDetail.printingSerialNumber,
          showToCustomer: true,
        },
        {
          key: 'insuranceTypeCode',
          value: contractDetail.insuranceType.code,
          showToCustomer: true,
        },
        {
          key: 'userType',
          value: contractDetail.userType,
          showToCustomer: true,
        },
        {
          key: 'fullName',
          value: contractDetail.contractUser.fullName,
          showToCustomer: true,
        },
        {
          key: 'fullNameKana',
          value: contractDetail.contractUser.fullNameKana,
          showToCustomer: true,
        },
        {
          key: 'startDate',
          value: convertDate(contractDetail.startDate),
          showToCustomer: true,
        },
      ];
      // 基本情報の表示値をマッピング
      const uneditableRightBaseRecords = [
        {
          key: 'contractUserFullAddress',
          value: `${contractDetail.contractUser.postalCode} ${contractDetail.contractUser.fullAddress}`,
          showToCustomer: true,
        },
        {
          key: 'insuranceBuildingFullAddress',
          value: contractDetail.insuranceBuilding.fullAddress,
          showToCustomer: true,
        },
        {
          key: 'insuranceObject1',
          value: this.insuranceObjectName1,
          showToCustomer: true,
        },
        {
          key: 'insuranceObject2',
          value: this.insuranceObjectName2,
          showToCustomer: true,
        },
        {
          key: 'totalPrice',
          value: contractDetail.plans.find(plan => plan.number === 1).payment.transferTypeName,
          showToCustomer: true,
        },
        {
          key: 'organizationCode',
          value: contractDetail.organization.code,
        },
        {
          key: 'organizationName',
          value: contractDetail.organization.name,
        },
        {
          key: 'rightOfPledge',
          value: contractDetail.rightOfPledge,
        },
      ];
      // 基本情報の表示値をマッピング
      const editableLeftBaseRecords = [
        {
          key: 'birthday',
          value: convertDateOfBirth(contractDetail.contractUser.birthday),
          showToCustomer: true,
        },
        {
          key: 'mobileNumber',
          value: contractDetail.contractUser.mobileNumber,
        },
        {
          key: 'mail',
          value: contractDetail.contractUser.mail,
        },
      ];
      // 基本情報の表示値をマッピング
      const editableRightBaseRecords = [
        {
          key: 'extraFile',
          value:
            extraFilelist.length == 0
              ? this.$t('label.noExtraFile')
              : this.$t('label.hasExtraFile'),
          showToCustomer: true,
        },
        {
          key: 'comment',
          value: splitMessageLine(contractDetail.comment),
          showToCustomer: true,
        },
      ];

      // 代理店情報の表示値をマッピング
      const leftAgencyRecords = [
        {
          key: 'agencyCode',
          value: contractDetail.contractAgency.code,
        },
        {
          key: 'agencyCodeUser',
          value: contractDetail.agency.agencyCode,
        },
      ];
      // 代理店情報の表示値をマッピング
      const rightAgencyRecords = [
        {
          key: 'agencyName',
          value: contractDetail.contractAgency.name,
          showToCustomer: true,
        },
        {
          key: 'staffName',
          value: contractDetail.contractAgency.staffName,
          showToCustomer: true,
        },
        {
          key: 'agencyPhoneNumber',
          value: contractDetail.contractAgency.phoneNumber,
          showToCustomer: true,
        },
      ];

      // 営業店情報の表示値をマッピング
      const salesRecords = [
        {
          key: 'salesCode',
          value: contractDetail.agency.sale
            ? contractDetail.agency.sale.salesCode
            : null,
        },
        {
          key: 'salesDepartmentName',
          value: contractDetail.agency.sale
            ? contractDetail.agency.sale.salesDepartmentName
            : null,
        },
        {
          key: 'salesSectionName',
          value: contractDetail.agency.sale
            ? contractDetail.agency.sale.salesSectionName
            : null,
        },
      ];

      // 案件送信情報が複数存在する場合、先頭の要素を格納する
      const contractSubmit =
        contractDetail.contractSubmits.length > 0
          ? contractDetail.contractSubmits[0]
          : null;

      // 案件送信情報が存在する場合、選択されたプランを格納する
      const selectedPlan = contractSubmit
        ? contractDetail.plans.find(
            plan => plan.id === contractSubmit.planId
          )
        : null;

      // お客さま確認結果情報の表示値をマッピング
      const leftConfirmRecords = [
        {
          key:
            contractDetail.status === ContractStatus.Concluded
              ? 'contractDate'
              : 'completionDate',
          value: contractSubmit
            ? contractSubmit.contractDate
              ? moment(contractSubmit.contractDate).format('YYYY/MM/DD HH:mm')
              : null
            : null,
        },
        {
          key: 'selectedPlan',
          value: selectedPlan ? selectedPlan.planName : null,
        },
        {
          key: 'insuredUpload',
          value: contractDetail.insuredUploads,
        },
      ];

      // SMS・メール送信用本文取得
      const sendTextManagements = await getSendTextManagements(
        this.$route.params['contractId']
      );
      const sendTextManagementOfSms = sendTextManagements.find(
        sendTextManagement => sendTextManagement.sendTarget === 'sms'
      );
      const sendTextManagementOfMail = sendTextManagements.find(
        sendTextManagement => sendTextManagement.sendTarget === 'mail'
      );

      // SMS・メール送信用本文(テンプレート)
      const sendTemplateTextManagements = await getSendTemplateTextManagements();

      // SMS送信本文
      const smsMessageRecords = [
        {
          id: sendTextManagementOfSms ? sendTextManagementOfSms.id : null,
          key: 'proposingSmsBody',
          isEnabled: this.isSelectAndEditSmsMailMessageBodyButtonEnabled
            ? contractDetail.contractUser.mobileNumber
              ? true
              : false
            : false,
          value: sendTextManagementOfSms
            ? sendTextManagementOfSms.proposingBody
            : null,
          template: {
            default: 'pattern1',
            patternList: {
              pattern1: [
                sendTemplateTextManagements.find(
                  sendTemplateTextManagement =>
                    sendTemplateTextManagement.senderType === 'sms' &&
                    sendTemplateTextManagement.sceneType === 'propose' &&
                    sendTemplateTextManagement.pattern === 1
                ).message,
              ],
              pattern2: [
                sendTemplateTextManagements.find(
                  sendTemplateTextManagement =>
                    sendTemplateTextManagement.senderType === 'sms' &&
                    sendTemplateTextManagement.sceneType === 'propose' &&
                    sendTemplateTextManagement.pattern === 2
                ).message,
              ],
            },
          },
          rules: this.choiceSendMessageRule('proposingSmsBody'),
          counterMax: MaxLength.SmsMessageBody,
        },
        {
          id: sendTextManagementOfSms ? sendTextManagementOfSms.id : null,
          key: 'concludedSmsBody',
          isEnabled: this.isSelectAndEditSmsMailMessageBodyButtonEnabled
            ? contractDetail.contractUser.mobileNumber
              ? true
              : false
            : false,
          value: sendTextManagementOfSms
            ? sendTextManagementOfSms.concludedBody
            : null,
          template: {
            default: 'pattern1',
            patternList: {
              pattern1: [
                sendTemplateTextManagements.find(
                  sendTemplateTextManagement =>
                    sendTemplateTextManagement.senderType === 'sms' &&
                    sendTemplateTextManagement.sceneType === 'conclude' &&
                    sendTemplateTextManagement.pattern === 1
                ).message,
              ],
              pattern2: [
                sendTemplateTextManagements.find(
                  sendTemplateTextManagement =>
                    sendTemplateTextManagement.senderType === 'sms' &&
                    sendTemplateTextManagement.sceneType === 'conclude' &&
                    sendTemplateTextManagement.pattern === 2
                ).message,
              ],
            },
          },
          rules: this.choiceSendMessageRule('concludedSmsBody'),
          counterMax: MaxLength.SmsMessageBody,
        },
      ];

      // メール送信本文
      const mailMessageRecords = [
        {
          id: sendTextManagementOfMail ? sendTextManagementOfMail.id : null,
          key: 'proposingEmailBody',
          isEnabled: this.isSelectAndEditSmsMailMessageBodyButtonEnabled
            ? contractDetail.contractUser.mail
              ? true
              : false
            : false,
          value: sendTextManagementOfMail
            ? sendTextManagementOfMail.proposingBody
            : null,
          template: {
            default: 'pattern1',
            patternList: {
              pattern1: sendTemplateTextManagements.find(
                sendTemplateTextManagement =>
                  sendTemplateTextManagement.senderType === 'mail' &&
                  sendTemplateTextManagement.sceneType === 'propose' &&
                  sendTemplateTextManagement.pattern === 1
              ).message,
            },
          },
          rules: this.choiceSendMessageRule('proposingEmailBody'),
          counterMax: MaxLength.EmailMessageBody,
        },
        {
          id: sendTextManagementOfMail ? sendTextManagementOfMail.id : null,
          key: 'concludedEmailBody',
          isEnabled: this.isSelectAndEditSmsMailMessageBodyButtonEnabled
            ? contractDetail.contractUser.mail
              ? true
              : false
            : false,
          value: sendTextManagementOfMail
            ? sendTextManagementOfMail.concludedBody
            : null,
          template: {
            default: 'pattern1',
            patternList: {
              pattern1: sendTemplateTextManagements.find(
                sendTemplateTextManagement =>
                  sendTemplateTextManagement.senderType === 'mail' &&
                  sendTemplateTextManagement.sceneType === 'conclude' &&
                  sendTemplateTextManagement.pattern === 1
              ).message,
            },
          },
          rules: this.choiceSendMessageRule('concludedEmailBody'),
          counterMax: MaxLength.EmailMessageBody,
        },
      ];

      // 案件詳細表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'salesRecords', salesRecords);
      this.$set(this, 'statusRecords', statusRecords);
      this.$set(this, 'noteRecords', noteRecords);
      this.$set(this, 'sendMessageRecords', sendMessageRecords);
      this.$set(this, 'uneditableLeftBaseRecords', uneditableLeftBaseRecords);
      this.$set(this, 'uneditableRightBaseRecords', uneditableRightBaseRecords);
      this.$set(this, 'editableLeftBaseRecords', editableLeftBaseRecords);
      this.$set(this, 'editableRightBaseRecords', editableRightBaseRecords);
      this.$set(this, 'leftAgencyRecords', leftAgencyRecords);
      this.$set(this, 'rightAgencyRecords', rightAgencyRecords);
      this.$set(this, 'leftConfirmRecords', leftConfirmRecords);
      this.$set(this, 'smsMessageRecords', smsMessageRecords);
      this.$set(this, 'mailMessageRecords', mailMessageRecords);

      // SMS/メール送信ボタンを有効化・無効化判別
      if (
        (!contractDetail.contractUser.mobileNumber && // 基本情報の携帯電話番号とメールアドレス両方が登録されていない場合
          !contractDetail.contractUser.mail) ||
        !contractDetail.contractAgency.name || // 代理店情報の表のいずれかの項目が登録されていない場合
        !contractDetail.contractAgency.staffName ||
        !this.hasRole('sendMessage')
      ) {
        // SMS/メール送信ボタンを無効化
        this.$set(this, 'isSendMsgButtonDisabled', true);
      } else {
        // SMS/メール送信ボタンを有効化
        this.$set(this, 'isSendMsgButtonDisabled', false);
      }

      // プラン一覧に選択されたプランの行を色つける
      if (
        contractDetail.status === ContractStatus.Concluded ||
        contractDetail.status === ContractStatus.Accounted ||
        contractDetail.status ===
          ContractStatus.AccountedWithDocRequired ||
        contractDetail.status === ContractStatus.AccountingError ||
        contractDetail.status === ContractStatus.UnAccounted
      ) {
        this.selectedPlanId = contractSubmit.planId ? contractSubmit.planId : null;
      } else {
        this.selectedPlanId = null;
      }
    },

    // 編集中のプラン情報を取得
    getEditPlan() {
      // 編集中のプランIDと一致する場合
      return this.contract.plans.find(plan => plan.id === this.editPlanId);
    },

    showBackgroundColor(planId) {
      // 選択されたプランに背景色を表示させる判定
      return planId === this.selectedPlanId;
    },

    showAttachDocBackgroundColor(planId) {
      // 必要書類関連情報編集ダイアログの背景色を表示させる判定
      return planId === this.selectedPlanId
        ? { margin: 0 }
        : { margin: 0, background: '#ffc0cb' };
    },

    mapPlanInfo(planInfo) {
      return planInfo.map(plan => ({
        id: plan.id,
        planName: plan.planName,
        insurancePeriodText: plan.insurancePeriodText,
        earthquakeInsurancePeriodText: plan.earthquakeInsurancePeriodText,
        grandTotalPrice: plan.planPremium.grandTotalPrice,
        totalPremium: plan.planPremium.totalPremium,
        paymentTypeName: plan.payment.paymentTypeName,
        attachDoc: plan.attachDoc,
        message: splitMessageLine(plan.message),
        comment: splitMessageLine(plan.comment),
      }));
    },

    // 基本情報編集のキャンセル
    cancelEditBaseInfo() {
      this.$set(this, 'showEditBaseInfoDialog', false);
    },

    // 代理店情報編集のキャンセル
    cancelEditAgencyInfo() {
      this.$set(this, 'showEditAgencyInfoDialog', false);
    },

    // 必要書類関連情報編集のキャンセル
    cancelEditPlanMessage() {
      this.$set(this, 'showEditPlanMessageDialog', false);
    },

    // プラン説明編集のキャンセル
    cancelEditPlanComment() {
      this.$set(this, 'showEditPlanCommentDialog', false);
    },

    // URL無効化警告ダイアログ
    closeShowUrlInvalidationWarningDialog() {
      this.$set(this, 'showUrlInvalidationWarningDialog', false);
    },

    // 編集不可警告ダイアログ
    closeNotEditableWarningDialog() {
      this.$set(this, 'showNotEditableWarningDialog', false);
    },

    // 再送信確認ダイアログのキャンセル
    cancelResendingConfirmDialog() {
      this.$set(this, 'showResendingConfirmDialog', false);
    },

    // 送信方法・認証方法選択ダイアログのキャンセルボタン
    cancelSelectSendMethodAndAuthMethodDialog() {
      this.$set(this, 'showSelectSendMethodAndAuthMethodDialog', false);
    },

    // ステータス変更のキャンセル
    cancelStatusChange() {
      this.$set(this, 'showStatusChangeDialog', false);
    },

    // ステータス変更確認のキャンセル
    cancelStatusChangeConfirm() {
      this.$set(this, 'showStatusChangeConfirmDialog', false);
    },

    // ステータス変更ボタンの押下時
    onClickStatusChangeButton() {
      const statusList = Status.getChoices(this.contract.status);
      this.$set(this, 'statusList', statusList);

      const targets = JSON.parse(JSON.stringify(this.editStatusDefaultTargets));
      const editStatusTargets = targets.map(target => {
        // リストの取得
        target.list = this[target.listName]
        return target;
      });

      this.$set(this, 'editStatusTargets', editStatusTargets);
      this.$set(
        this,
        'statusChangeDialogText',
        this.$t(`label.currentStatus`, {
          status: ContractStatusDisplay[this.contract.status],
        })
      );
      this.$set(this, 'showStatusChangeDialog', true);
    },

    // ステータス変更の確認
    async confirmStatusChange(item) {
      // ステータス変更ダイアログを非表示にする
      this.$set(this, 'showStatusChangeDialog', false);

      // ステータス変更時イベント
      const statusChangeEvent = Status.getStatusChangeEvent(
        this.contract.status,
        item.status
      );

      // 変更後のステータスをセットする
      this.$set(this, 'newStatus', item.status);
      // ステータス変更イベントをセットする
      this.$set(this, 'statusChangeEvent', statusChangeEvent);

      // 特定のステータス変更イベントが存在する場合
      if (statusChangeEvent && statusChangeEvent !== '*') {
        // 送信方法
        const sendFlg =
          // 携帯電話番号、メールアドレスが入力済である場合
          !!this.contract.contractUser.mobileNumber && !!this.contract.contractUser.mail
            ? this.$t(`label.mailAndSms`)
            : // 携帯電話番号のみ入力済である場合
            !!this.contract.contractUser.mobileNumber && !this.contract.contractUser.mail
            ? this.$t(`label.sms`)
            : // その他の場合
              this.$t(`label.mail`);
        // 確認文言
        const message =
          statusChangeEvent ===
          ContractStatusChangeEvent.ConfirmWithdrawalWithNotify
            ? // 送信方法に応じて文言を出し分ける
              this.$t(
                `description.statusChangeConfirmDialog.${statusChangeEvent}`,
                { sendFlg: sendFlg }
              )
            : this.$t(
                `description.statusChangeConfirmDialog.${statusChangeEvent}`
              );
        this.$set(this, 'statusChangeConfirmDialogConfirmText', message);

        let targets = JSON.parse(
          JSON.stringify(this.editStatusChangeConfirmDefaultTargets)
        );
        // 通知有無選択が不要な場合、通知有無選択欄を削除する
        if (
          statusChangeEvent !==
          ContractStatusChangeEvent.ConfirmWithdrawalWithNotify
        )
          targets = targets.filter(
            target => target.targetName !== 'needsNotify'
          );

        this.$set(this, 'editStatusChangeConfirmTargets', targets);
        // ステータス変更確認ダイアログを表示する
        this.$set(this, 'showStatusChangeConfirmDialog', true);
      } else {
        await this.updateStatus();
      }
    },

    // ステータスの更新
    async updateStatus(item) {
      // 取り下げ確認かつ通知が必要である場合
      if (
        this.statusChangeEvent ===
          ContractStatusChangeEvent.ConfirmWithdrawalWithNotify &&
        item.needsNotify
      ) {
        // ステータス変更確認ダイアログを非表示にする
        this.$set(this, 'showStatusChangeConfirmDialog', false);

        const destinationInfo = {
          contractId: this.contract.id,
          fullNameKanji: this.contract.contractUser.fullName,
          status: this.contract.status,
          mobileNumber: this.contract.contractUser.mobileNumber,
          mail: this.contract.contractUser.mail,
          isSms: !!this.contract.contractUser.mobileNumber,
          isMail: !!this.contract.contractUser.mail,
          needsSelectAuth: false,
        };
        this.$set(this, 'destinationInfo', destinationInfo);
        this.sendingMessage(destinationInfo, true);
      } else {
        const updateParam = {
          status: this.newStatus,
          isMail: this.destinationInfo.isMail,
          isSms: this.destinationInfo.isSms,
        };
        // 案件ステータスの更新
        const result = await putContractStatus(
          this.contract.id,
          updateParam
        ).catch(() => {
          this.$refs.errorDialog.open(
            this.$t('title.agency.statusUpdateError'),
            this.$t('error.contractStatusUpdateBadRequest')
          );
          this.$refs.sendingConfirmDialog.close();
        });

        // 案件ステータスの更新に失敗した場合、以降の処理を中止する
        if (!result) return;

        // 案件情報表示を更新する
        await this.fetchContractDetail();

        // ステータス変更確認ダイアログを非表示にする
        this.$set(this, 'showStatusChangeConfirmDialog', false);
        this.$refs.sendingConfirmDialog.close();
        this.$refs.completedDialog.open(
          this.$t('title.agency.statusChangeResult'),
          this.$t('success.statusChange')
        );
      }
    },

    // 基本情報の更新
    async updateBaseInfoConfirm(item) {
      const data = {
        contractId: this.contract.id,
        birthday: item.birthday,
        mobileNumber: item.mobileNumber,
        mail: item.mail,
        comment: item.comment,
      };

      // 案件詳細取得
      const contractDetail = await getContractDetail(
        this.contract.id
      ).catch(() => {});

      // ステータスがお客様確認待ちの場合、基本情報の生年月日、携帯電話番号、メールアドレスが変更された際に（新規登録を除く）、ステータスを巻き戻す警告ダイアログを表示させる
      if (
        contractDetail.status === ContractStatus.Waiting &&
        ((contractDetail.contractUser.birthday &&
          contractDetail.contractUser.birthday != item.birthday) ||
          (contractDetail.contractUser.mobileNumber &&
            contractDetail.contractUser.mobileNumber != item.mobileNumber) ||
          (contractDetail.contractUser.mail &&
            contractDetail.contractUser.mail != item.mail))
      ) {
        data.isRestoreStatus = true;
        this.$set(this, 'updateBaseInfoCondition', data);
        this.$set(this, 'showUrlInvalidationWarningDialog', true);
      } else {
        data.isRestoreStatus = false;
        this.$set(this, 'updateBaseInfoCondition', data);
        this.updateBaseInfo();
      }
    },
    // 基本情報の更新
    async updateBaseInfo() {
      this.$set(this, 'showUrlInvalidationWarningDialog', false);

      // 案件情報を更新
      const result = await updateContractDetail(
        this.updateBaseInfoCondition
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.contractUpdateError'),
          this.$t('error.contractUpdateBadRequest')
        );
      });

      // 案件の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件情報表示を更新する
      await this.fetchContractDetail();
      this.$set(this, 'showEditBaseInfoDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 代理店情報の更新
    async updateAgencyInfo(item) {
      const data = {
        contractId: this.contract.id,
        agencyName: item.name,
        staffName: item.staffName,
        agencyPhoneNumber: item.phoneNumber,
      };
      // 案件情報を更新
      const result = await updateContractDetail(data).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.contractUpdateError'),
          this.$t('error.contractUpdateBadRequest')
        );
      });

      // 案件の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件情報表示を更新する
      await this.fetchContractDetail();
      this.$set(this, 'showEditAgencyInfoDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // 必要情報関連情報の更新
    async updatePlanMessage(item) {
      const data = {
        message: item.message,
      };
      // プラン情報を更新
      const result = await updatePlanDetail(
        this.contract.id,
        this.editPlanId,
        data
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.planUpdateError'),
          this.$t('error.planUpdateBadRequest')
        );
      });

      // プラン情報の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件情報表示を更新する
      await this.fetchContractDetail();
      this.$set(this, 'showEditPlanMessageDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // プラン説明の更新
    async updatePlanComment(item) {
      const data = {
        comment: item.comment,
      };
      // プラン情報を更新
      const result = await updatePlanDetail(
        this.contract.id,
        this.editPlanId,
        data
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.planUpdateError'),
          this.$t('error.planUpdateBadRequest')
        );
      });

      // プラン情報の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件情報表示を更新する
      await this.fetchContractDetail();
      this.$set(this, 'showEditPlanCommentDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // メモ編集ボタンの押下時
    async onClickEditNoteButton() {
      const targets = JSON.parse(JSON.stringify(this.editNoteDefaultTargets));
      const data = {
        note: this.contract.note,
      };
      const editNoteTargets = targets.map(target => {
        target.value = data[target.targetName];
        return target;
      });

      this.$set(this, 'editNoteTargets', editNoteTargets);
      this.$set(this, 'showEditNoteDialog', true);
    },

    // メモの更新
    async updateNote(item) {
      const data = {
        contractId: this.contract.id,
        note: item.note,
      };
      // 案件情報を更新
      const result = await updateContractDetail(data).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.contractUpdateError'),
          this.$t('error.contractUpdateBadRequest')
        );
      });

      // 案件情報の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      // 案件一覧表示を更新する
      await this.fetchContractDetail();
      this.$set(this, 'showEditNoteDialog', false);

      this.$refs.completedDialog.open(
        this.$t('title.agency.updateResult'),
        this.$t('success.updated')
      );
    },

    // メモ編集のキャンセル
    cancelEditNote() {
      this.$set(this, 'showEditNoteDialog', false);
    },

    // 基本情報編集ボタンの押下時
    onClickEditContractButton() {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('label.editContract')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      const targets = JSON.parse(
        JSON.stringify(this.editBaseInfoDefaultTargets)
      );
      const data = {
        birthday: this.contract.contractUser.birthday,
        mobileNumber: this.contract.contractUser.mobileNumber,
        mail: this.contract.contractUser.mail,
        comment: this.contract.comment,
      };
      const editBaseInfoTargets = targets.map(target => {
        target.value = data[target.targetName];
        return target;
      });

      this.$set(this, 'editBaseInfoTargets', editBaseInfoTargets);
      this.$set(this, 'showEditBaseInfoDialog', true);
    },

    // 代理店情報編集ボタンの押下時
    async onClickEditAgencyButton() {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('label.editAgency')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      const targets = JSON.parse(
        JSON.stringify(this.editAgencyInfoDefaultTargets)
      );
      const data = {
        name: this.contract.contractAgency.name,
        staffName: this.contract.contractAgency.staffName,
        phoneNumber: this.contract.contractAgency.phoneNumber,
      };
      const editAgencyInfoTargets = targets.map(target => {
        target.value = data[target.targetName];
        return target;
      });

      this.$set(this, 'editAgencyInfoTargets', editAgencyInfoTargets);
      this.$set(this, 'showEditAgencyInfoDialog', true);
    },

    // 必要関連情報編集ボタンの押下時
    async onClickEditPlanMessageButton(planId) {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('label.editPlanMessage')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      this.$set(this, 'editPlanId', planId);

      const targets = JSON.parse(
        JSON.stringify(this.editPlanMessageDefaultTargets)
      );

      const plan = this.getEditPlan();
      const data = {
        message: plan.message,
      };
      const editPlanMessageTargets = targets.map(target => {
        target.value = data[target.targetName];
        return target;
      });

      this.$set(this, 'editPlanMessageTargets', editPlanMessageTargets);
      this.$set(this, 'showEditPlanMessageDialog', true);
    },

    // プラン説明編集ボタンの押下時
    async onClickEditPlanCommentButton(planId) {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('label.editPlanComment')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      this.$set(this, 'editPlanId', planId);

      const targets = JSON.parse(
        JSON.stringify(this.editPlanCommentDefaultTargets)
      );

      const plan = this.getEditPlan();
      const data = {
        comment: plan.comment,
      };
      const editPlanCommentTargets = targets.map(target => {
        target.value = data[target.targetName];
        return target;
      });

      this.$set(this, 'editPlanCommentTargets', editPlanCommentTargets);
      this.$set(this, 'showEditPlanCommentDialog', true);
    },

    // 契約情報反映
    async onClickReflectButton(currentValue, itemName) {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('label.reflectContractInfo')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      // 契約情報
      const contractInfo = {
        valueToOverwriteNameColumn: this.contract.contractAgency.valueToOverwriteNameColumn,
        valueToOverwriteStaffNameColumn: this.contract.contractAgency.valueToOverwriteStaffNameColumn,
      };
      this.$refs.reflectContractInfoDialog.init(
        currentValue,
        contractInfo,
        itemName
      );
      this.$refs.reflectContractInfoDialog.open();
    },
    async onSuccessReflectLoginUserInfo(condition) {
      this.$refs.reflectContractInfoDialog.close();
      condition.contractId = this.$route.params['contractId'];
      // 編集項目を更新
      const result = await updateContractDetail(condition).catch(() => {
        this.$refs.reflectContractInfoDialog.onErrorReflect();
      });
      if (!result) return;
      this.$refs.reflectContractInfoDialog.close();
      // 更新成功後に画面にセットする値を更新
      if (Object.keys(result).indexOf('id') !== -1) {
        this.$refs.completedDialog.open(
          this.$t('label.reflectContractInfo'),
          this.$t('success.reflected')
        );
        this.fetchContractDetail();
      }
    },

    // 重要事項等説明書印刷／ダウンロードボタンの押下時
    async onClickFilePrintAndDownloadButton() {
      // 申込内容以外データ一覧の更新
      this.extraFilelist = await getExtraPictureList(this.contract.id);
      this.$refs.fileListDialog.set(
        this.contract.id,
        this.contract.insuranceType.importantMatter,
        this.leftConfirmRecords.find(record => record.key === 'insuredUpload')
          .value,
        this.extraFilelist
      );
      this.$refs.fileListDialog.open();
    },

    // 案件削除
    onClickContractDeleteButton() {
      this.$refs.contractDeleteConfirmDialog.open();
    },

    // 案件削除確認の成功時
    async onSuccessContractDeleteConfirm() {
      // 案件の削除
      const result = await deleteContract(this.contract.id).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.contractDeleteError'),
          this.$t('error.contractDeleteBadRequest')
        );
      });

      // 案件が削除できなかった場合、以降の処理を中止する
      if (!result) return;

      this.$refs.contractDeleteConfirmDialog.close();

      // ホーム画面に遷移する
      this.$router.push('/Home');
    },

    // 申込内容以外データアップロード
    async onClickExtraFileUploadButton() {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('title.agency.extraFileUpload')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      this.$refs.editExtraFileDialog.open(
        this.$route.params['contractId'],
        this.contract.agency.id
      );

      this.setLoading(true);
      // 現在登録されているファイルをサーバから取得して初期化する
      this.extraFilelist = await getExtraPictureList(
        this.$route.params['contractId']
      );
      this.setLoading(false);
    },

    onClickExtraFileCreate() {
      let fileNameList = this.extraFilelist.map(extraFile => extraFile.name);
      this.$set(this, 'fileNameList', fileNameList.join(','));
      // 重複確認のためファイル名リストのバリデーションを更新
      this.$set(
        this,
        'createExtraFileTargets',
        JSON.parse(
          JSON.stringify(this.createExtraFileDefaultTargets).replace(
            '{fileNameList}',
            this.fileNameList
          )
        )
      );

      this.$set(this, 'showCreateExtraFileDialog', true);
    },
    cancelCreateExtraFile() {
      this.$set(this, 'showCreateExtraFileDialog', false);
    },
    async createExtraFile(item) {
      this.$set(this, 'isSendData', true);
      const extraFileDetails = {
        name: item.file.name,
        comment: item.comment,
      };
      const result = await registerExtraPictures(
        this.contract.id,
        extraFileDetails,
        item.file
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      });

      if (result && Object.keys(result).indexOf('id') !== -1) {
        // 申込内容以外データ一覧の更新
        this.extraFilelist = await getExtraPictureList(
          this.$route.params['contractId']
        );
        await this.fetchContractDetail();
        this.$refs.completedDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('success.created')
        );
      }
      this.$set(this, 'showCreateExtraFileDialog', false);
      this.$set(this, 'isSendData', false);
    },
    async onClickExtraFilePreview(item) {
      this.setLoading(true);
      const index = this.extraFilelist.indexOf(item);
      // 画像情報を保持していない場合のみ取得
      if (
        !Object.prototype.hasOwnProperty.call(this.extraFilelist[index], 'img')
      ) {
        const blob = await getExtraPicture(this.contract.id, item.id);
        const img = await convertBlobToBase64(blob);
        const imageData = await getImgWidthAndHeight(img);

        this.$set(this.extraFilelist[index], 'img', {
          src: img,
          width: imageData.width,
          height: imageData.height,
        });
      }
      this.$set(this, 'previewImg', this.extraFilelist[index].img.src);
      this.$set(this, 'showImgPreviewDialog', true);
      this.setLoading(false);
    },
    onClickExtraFileEdit(item) {
      this.$set(this, 'editExtraFileId', item.id);
      this.$set(
        this,
        'editExtraFileTargets',
        JSON.parse(JSON.stringify(this.editExtraFileDefaultTargets))
      );
      this.editExtraFileTargets = this.editExtraFileDefaultTargets.map(
        editTarget => {
          editTarget.value = item[editTarget.targetName];
          return editTarget;
        }
      );
      this.$set(this, 'showEditExtraFileDialog', true);
    },
    cancelExtraFileEdit() {
      this.$set(this, 'showEditExtraFileDialog', false);
    },
    async updateExtraFile(item) {
      this.$set(this, 'isSendData', true);
      const extraFileDetails = {
        comment: item.comment,
      };
      const result = await editExtraPictures(
        this.contract.id,
        this.editExtraFileId,
        extraFileDetails
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      });

      if (result) {
        // 申込内容以外データ一覧の更新
        this.extraFilelist = await getExtraPictureList(
          this.$route.params['contractId']
        );
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
      }

      this.$set(this, 'showEditExtraFileDialog', false);
      this.$set(this, 'isSendData', false);
    },
    onClickExtraFileDelete(item) {
      this.$set(this, 'showDeleteExtraFileDialog', true);
      this.$set(this, 'deleteExtraFileId', item.id);
      this.$set(
        this,
        'deleteExtraFileText',
        this.$t('description.deleteExtraFile', { fileName: item.name })
      );
    },
    cancelExtraFileDelete() {
      this.$set(this, 'showDeleteExtraFileDialog', false);
    },
    async deleteExtraFile() {
      const result = await deleteExtraPictures(
        this.contract.id,
        this.deleteExtraFileId
      ).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.deleteResult'),
          this.$t('error.deleteFailed')
        );
      });

      if (result) {
        // 申込内容以外データ一覧の更新
        this.extraFilelist = await getExtraPictureList(
          this.$route.params['contractId']
        );
        await this.fetchContractDetail();
        this.$refs.completedDialog.open(
          this.$t('title.agency.deleteResult'),
          this.$t('success.deleted')
        );
      }
      this.$set(this, 'showDeleteExtraFileDialog', false);
    },

    // 編集・参照ボタンの押下時
    onClickSendMessageButton() {
      // 編集不可の場合、以降の処理を中止
      if (this.notEditableFlg) {
        this.$set(
          this.notEditableWarning,
          'title',
          this.$t('title.agency.sendMessage')
        );
        this.$set(this, 'showNotEditableWarningDialog', true);
        return;
      }
      this.$refs.sendMessageDialog.open();
    },

    // 編集ボタンの押下時
    async onSuccessSendMessage(data) {
      const contractId = this.$route.params['contractId'];
      this.$refs.selectAndEditSendMessageDialog.init(
        contractId,
        data.isSms,
        data.message,
        this.contractInfoForReplace
      );
      this.$refs.selectAndEditSendMessageDialog.open();
    },

    async onSuccessSelectAndEditSendMessage(messageBody, sendTextManagementId) {
      // 送信用メッセージ本文（保存用）
      this.$set(this, 'targetSendTextManagementId', sendTextManagementId);
      this.$set(this, 'sendMessageForSave', messageBody);

      // 送信本文置換（プレビュー用）
      const sendMessageForPreview = await replaceUnspecifiedInfo(
        Object.values(messageBody)[0],
        this.contractInfoForReplace
      );

      const smsTypeList = this.smsMessageRecords.map(smsMessageRecord => {
        return smsMessageRecord.key;
      });
      if (smsTypeList.indexOf(Object.keys(messageBody)[0]) !== -1) {
        // SMSの場合、送信件数・費用の計算
        const charactersCount = sendMessageForPreview.length;
        const numberOfSmsSent =
          charactersCount <= SentSms.MaxCharactersWithOnlyOneSms
            ? Math.ceil(charactersCount / SentSms.MaxCharactersWithOnlyOneSms)
            : Math.ceil(charactersCount / SentSms.MaxCharactersPerSms);
        const feeOfSmsSent = numberOfSmsSent * SentSms.FeePerSms;
        this.$set(
          this,
          'textForNumberOfTransmissions',
          this.$t('description.messageContent.textForNumberOfSmsSent', {
            number: numberOfSmsSent,
          })
        );
        this.$set(
          this,
          'textForSmsTransmissionFee',
          this.$t('description.messageContent.textForSmsTransmissionFee', {
            fee: feeOfSmsSent,
          })
        );
        // {modificationContent}タグが挿入された場合、送信件数・費用上昇の文言を表示する
        this.$set(
          this,
          'noticeOfModificationContent',
          Object.values(messageBody)[0].match(/{modificationContent}/g)
            ? this.$t('description.messageContent.modificationNote')
            : null
        );
      } else {
        // メール送信時の場合、送信件数・料金・費用上昇を表示しない
        this.$set(this, 'textForNumberOfTransmissions', null);
        this.$set(this, 'textForSmsTransmissionFee', null);
        this.$set(this, 'noticeOfModificationContent', null);
      }
      // 送信本文プレビューの表示
      this.$set(this, 'sendMessageForPreview', sendMessageForPreview);
      this.$set(this, 'showSendPreviewDialog', true);
    },
    closeSendPreviewDialog() {
      this.$set(this, 'showSendPreviewDialog', false);
    },
    async saveSendMessage() {
      if (this.showSendPreviewDialog) {
        this.$set(this, 'showSendPreviewDialog', false);
      }
      // DB更新処理
      const contractId = this.$route.params['contractId'];
      const result = await updateSendTextManagement(
        this.targetSendTextManagementId,
        contractId,
        this.sendMessageForSave
      ).catch(() => {
        this.$refs.selectAndEditSendMessageDialog.onErrorSend();
      });

      // 送信本文の更新に失敗した場合、以降の処理を中止する
      if (!result) return;

      this.$refs.selectAndEditSendMessageDialog.close();
      // 更新成功後に画面にセットする値を更新
      if (Object.keys(result).indexOf('id') !== -1) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t(
            `header.smsMailMessageTable.${Object.keys(this.sendMessageForSave)}`
          ) + this.$t('success.somethingUpdated')
        );
        this.fetchContractDetail();
      }
    },

    // 送信本文編集バリデーション
    choiceSendMessageRule(target) {
      let ruleList = [];
      switch (target) {
        case 'proposingSmsBody':
          ruleList = [
            'required',
            'requiredContractUrl',
            `max:${MaxLength.SmsMessageBody}`,
            'unusableModificationContent',
          ];
          break;
        case 'concludedSmsBody':
          ruleList = [
            'required',
            'requiredContractUrl',
            `max:${MaxLength.SmsMessageBody}`,
            'unusableModificationContent',
          ];
          break;
        case 'proposingEmailBody':
          ruleList = [
            'required',
            'requiredContractUrl',
            `max:${MaxLength.EmailMessageBody}`,
            'unusableModificationContent',
          ];
          break;
        case 'concludedEmailBody':
          ruleList = [
            'required',
            'requiredContractUrl',
            `max:${MaxLength.EmailMessageBody}`,
            'unusableModificationContent',
          ];
          break;
        default:
          break;
      }
      return ruleList.join('|');
    },

    // 遷移先判別
    async choiceDialog() {
      const contractId = this.$route.params['contractId'];
      // 案件詳細の取得
      const contractDetail = await getContractDetail(
        contractId
      ).catch(() => {});

      // 送信日は保険始期以降の場合、送信不可とする
      const currentDate = moment().format('YYYY-MM-DD');
      const startDate = moment(contractDetail.startDate).format(
        'YYYY-MM-DD'
      );
      if (moment(currentDate).isSameOrAfter(moment(startDate))) {
        this.$refs.errorDialog.open(
          this.$t('label.sendMsg'),
          this.$t('description.sendMailAndSms.passedAccessiblePeriod')
        );
        return;
      }

      // 編集不可のステータスの場合、送信不可とする
      if (this.notEditableFlg) {
        this.$refs.errorDialog.open(
          this.$t('label.sendMsg'),
          this.$t('description.sendMailAndSms.cannotResending', {
            status: ContractStatusDisplay[contractDetail.status],
          })
        );
        return;
      }

      const condition = {
        contractId: contractDetail.id,
        fullNameKanji: contractDetail.contractUser.fullName,
        status: contractDetail.status,
        mobileNumber: contractDetail.contractUser.mobileNumber,
        mail: contractDetail.contractUser.mail,
        needsSelectAuth: !!contractDetail.contractUser.birthday,
      };
      this.$set(this, 'destinationInfo', condition);

      // 未送信の場合、SMS/メール送信初期処理に移る
      if (condition.status == ContractStatus.Processing) {
        this.sendingMessage(condition, false);
      }
      // お客様確認待ちの場合、再送信確認ダイアログを表示
      if (condition.status == ContractStatus.Waiting) {
        this.$set(this, 'showResendingConfirmDialog', true);
      }
    },

    // 再送信確認処理
    resendingConfirm() {
      this.$set(this, 'showResendingConfirmDialog', false);
      this.sendingMessage(this.destinationInfo, false);
    },

    // SMS/メール送信初期処理
    sendingMessage(condition, isChangeStatusEvent = false) {
      let selectSendFlg = true;
      // 携帯電話番号とメールアドレスの両方記載がある場合
      if (condition.mobileNumber && condition.mail) {
        this.selectSendMethodAndAuthMethod(
          selectSendFlg,
          condition.needsSelectAuth,
          isChangeStatusEvent
        );
      }
      // 携帯電話番号のみの場合
      if (condition.mobileNumber && !condition.mail) {
        this.isSms = true;
        this.isMail = false;
        selectSendFlg = false;

        this.selectSendMethodAndAuthMethod(
          selectSendFlg,
          condition.needsSelectAuth,
          isChangeStatusEvent
        );
      }
      // メールアドレスのみの場合
      if (!condition.mobileNumber && condition.mail) {
        this.isMail = true;
        this.isSms = false;
        selectSendFlg = false;

        this.selectSendMethodAndAuthMethod(
          selectSendFlg,
          condition.needsSelectAuth,
          isChangeStatusEvent
        );
      }
    },

    // SMS/メール送信方法・認証方法選択
    selectSendMethodAndAuthMethod(
      selectSendFlg,
      needsSelectAuth,
      isChangeStatusEvent
    ) {
      // デフォルトのタイトルをセット
      this.$set(
        this,
        'selectSendMethodAndAuthMethodDialogTitle',
        this.$t('label.selectSendMethodAndAuthMethod')
      );

      let targets = JSON.parse(
        JSON.stringify(this.selectSendMethodAndAuthMethodDefaultTargets)
      );

      // 送信方法が決まっている場合、送信方法選択欄を削除する
      if (!selectSendFlg) {
        targets = targets.filter(target => target.targetName !== 'sendFlg');
        this.$set(
          this,
          'selectSendMethodAndAuthMethodDialogTitle',
          this.$t('label.selectAuthMethod')
        );
      }

      // 生年月日が存在しない場合、認証方法選択欄を削除する
      if (!this.contract.contractUser.birthday) {
        targets = targets.filter(target => target.targetName !== 'authFlg');
        this.$set(
          this,
          'selectSendMethodAndAuthMethodDialogTitle',
          this.$t('label.selectSendMethod')
        );
      }

      // 認証方法選択が不要な場合、認証方法選択欄を削除する
      if (!needsSelectAuth) {
        targets = targets.filter(target => target.targetName !== 'authFlg');
        this.$set(
          this,
          'selectSendMethodAndAuthMethodDialogTitle',
          this.$t('label.selectSendMethod')
        );
      }

      if (targets.length > 0) {
        this.$set(this, 'selectSendMethodAndAuthMethodTargets', targets);
        this.$set(this, 'isChangeStatusEventForOnce', isChangeStatusEvent);
        this.$set(this, 'showSelectSendMethodAndAuthMethodDialog', true);
      } else {
        // 選択項目が存在しない場合は直接確認ダイアログを表示する
        this.sendingConfirm(
          {
            authFlg: isChangeStatusEvent ? null : AuthType.policyNumber,
            isMail: this.isMail,
            isSms: this.isSms,
          },
          isChangeStatusEvent
        );
      }
    },

    // SMS/メール送信確認
    async sendingConfirm(sendMethod, isChangeStatusEvent = false) {
      if (this.isChangeStatusEventForOnce) {
        // FormsDialog経由のステータス変更イベントの場合
        this.$set(this, 'isChangeStatusEventForOnce', false);
        isChangeStatusEvent = true;
      }
      // 認証方法が存在しない場合、証券番号に固定（証券番号のみ存在の場合）
      // 認証方法選択が不要な場合、null を指定（手動でステータス変更の場合）
      sendMethod = {
        authFlg: sendMethod.authFlg
          ? sendMethod.authFlg
          : isChangeStatusEvent
          ? null
          : AuthType.policyNumber,
        isMail: sendMethod.sendFlg
          ? sendMethod.sendFlg === SenderFlg.Mail
          : this.isMail,
        isSms: sendMethod.sendFlg
          ? sendMethod.sendFlg === SenderFlg.Sms
          : this.isSms,
      };
      if (sendMethod.isMail || sendMethod.isSms) {
        this.$set(this, 'showSelectSendMethodAndAuthMethodDialog', false);
        // 初期値の設定
        this.$refs.sendingConfirmDialog.init(
          sendMethod,
          this.destinationInfo,
          isChangeStatusEvent
        );
        this.$refs.sendingConfirmDialog.open();
      }
    },

    // 送信確認の成功時
    async onSuccessSendingConfirm(condition) {
      // ステータス変更の送信イベント発生時に、ステータス更新処理のみを行う
      if (condition.isChangeStatusEvent) {
        this.$set(this, 'statusChangeEvent', null);
        return await this.updateStatus();
      }

      // メッセージ送信
      const result = await sendMessage(condition).catch(err => {
        console.log(err);
        const message =
          err.code == ErrorCode.MasterNotFound
            ? this.$t(`error.masterNotFound.${err.message}`)
            : this.$t('error.contractSendError');
        this.$refs.errorDialog.open(this.$t('title.agency.sendError'), message);
        return;
      });

      this.$refs.sendingConfirmDialog.close();
      // 送信成功後に画面にセットする値を更新
      if (result.isMailSent || result.isSmsSent) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.sendResult'),
          this.$t('success.sent')
        );
        this.fetchContractDetail();
      }
    },

    // ホーム画面に戻るボタン押下時
    onClickBackHomeButton() {
      // ホーム画面に遷移する
      this.$router.push('/Home');
    },
    onClickLockoutRelease() {
      this.$set(this, 'showLockoutReleaseDialog', true);
    },
    cancelLockoutRelease() {
      this.$set(this, 'showLockoutReleaseDialog', false);
    },
    async lockoutRelease() {
      const contractId = this.$route.params['contractId'];
      // 案件詳細の取得
      const response = await lockoutRelease(contractId).catch(() => {
        this.$refs.errorDialog.open(
          this.$t('title.agency.lockoutReleaseResult'),
          this.$t('error.lockoutRelease')
        );
      });

      if (response) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.lockoutReleaseResult'),
          this.$t('success.lockoutRelease')
        );
        await this.fetchContractDetail();
      }
      this.$set(this, 'showLockoutReleaseDialog', false);
    },
    checkLockoutStyle() {
      let statusStyale = '';
      switch (this.contract.lockoutStatus) {
        case ContractorLockoutStatus.Locked: {
          statusStyale = TextStyle.Alert;
          break;
        }
        case ContractorLockoutStatus.MaximumReSent: {
          statusStyale = TextStyle.Warring;
          break;
        }
        default:
      }

      return statusStyale;
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v_base_table_with_border {
  border: solid 1px;
}
.v_base_table_fill_width {
  width: 50%;
}
.base_td_category_text {
  width: 230px;
  background: #cccccc;
}
.v-data-table td {
  word-break: break-all;
}
.v-data-table {
  overflow: hidden;
}
</style>
